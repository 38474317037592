import Amazon from './Amazon.webp'
import Android from './Android.svg'
import Angular from './Angular.webp'
import Css from './Css.svg'
import Flutter from './Flutter.webp'
import Html from './Html.svg'
import Java from './Java.svg'
import Laravel from './Laravel.webp'
import Postgrel from './Postgrel.webp'
import Postman from './Postman.svg'
import Python from './Python.svg'
import React from './React.webp'
import Selenium from './Selenium.webp'

export const softwareLogo = {
    Amazon,
    Android,
    Angular,
    Css,
    Flutter,
    Html,
    Java,
    Laravel,
    Postgrel,
    Postman,
    Python,
    React,
    Selenium
}