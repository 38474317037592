import styled from "styled-components";
import { THEME } from "../../../Theme";

export const StyledLine = styled.div`
  button {
    background-color: #2d4979;
    color: #fff;
    padding: 7px 25px;
    margin: 10px 0px;
    border: 2px solid #51c0ac;
    border-radius: 3px;
    font-size: 18px;
  }
  .row {
    margin-top: 30px;
  }
  .column1 {
    padding-left: 50px;
  }
  .heading {
    color: #000;
    font-size: 20px;
  }
  .para {
    padding: 10px 0px;
  }
  li {
    font-size: 16px;
    margin-left: 30px;
  }
  .companyImg {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;

export const StyledCareers = styled.div`
  /* height: 85vh; */
  /* display: flex;
  justify-content: center; */

  & .leftWrapper {
    width: 100%;
    padding: 2rem;
    /* height: 50vh; */
    display: flex;
    /* align-items: flex-start; */
    justify-content: center;
    flex-direction: column;
    gap: 30px;

    @media ${THEME.TABLET} {
      height: 100%;
      /* width: 50%; */
    }

    h1 {
      text-align: center;
      font-family: "Raleway", sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px; /* 178.947% */
      letter-spacing: 0.76px;
      /* margin-bottom:20px; */

      @media ${THEME.TABLET} {
        font-size: 66.5px;
        line-height: 119px; /* 178.947% */
        margin-bottom: 20px;
      }
    }

    p {
      /* color: #fff; */
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 175.85% */
      letter-spacing: 0.9px;

      @media ${THEME.TABLET} {
        font-size: 15.047px;
        line-height: 26.46px; /* 175.85% */
      }
    }
  }

  & .box {
    height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 30px;
      box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
      border-bottom: 3px solid #4cb4f4;
      border-radius: 5px;

      h1 {
        padding: 15px 0px;
      }

      p {
        font-size: large;
      }

      .icons {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #dbf3fe;
        color: #4cb5f5;
        border-radius: 5px;
      }

      button{
        background: none;
        font-size: larger;
        border: none;
        font-size: large;
        font-weight: bold;
        padding: 15px 0px 0px 0px;
        cursor: pointer; 
      }
    }

    .box:hover{
      background: #4CB5F5;
      color: #fff;
      border-bottom: 3px solid blue;

      .icons {
        background: #ffff;
        color: #4cb5f5;
      }
    }
`;

export const StyledCareerDetails = styled.div`
 & .leftWrapper {
    width: 100%;
    padding: 2rem;
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;

    @media ${THEME.TABLET} {
      height: 100%;
    }

    h1 {
      text-align: start;
      font-family: "Raleway", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px; /* 178.947% */
      letter-spacing: 0.76px;
      /* margin-bottom:20px; */

      @media ${THEME.TABLET} {
        font-size: 43.5px;
        line-height: 119px; /* 178.947% */
        margin-bottom: 20px;
      }
    }
  }

   .btn{
      display: flex;
      justify-content: center;
    }

    .btnn{
      padding: 15px 30px;
      background: #4CB5F5;
      border: none;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }

  & .content{
    height: 400px;
    overflow: scroll;
    p{
      font-weight: 600;
      font-size: medium;
      line-height: 34px;
    }
  }
`

export const StyledApply = styled.div`

.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.four {
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  animation-delay: .5s;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    /* -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0); */
    scale: 0.8;
  }

  to {
    opacity: 1;
    scale: 1;

  }
}
/* @keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
} */

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
`