import React from 'react'
import { AimCardHolder, AimCardRow, AimContent, AimTilte } from './Style'
import { Fragment } from 'react'

export const AimCard = ({ data }) => {
    return (
        <Fragment>
            {
                data.map((item) => (
                    <AimCardHolder order={item.order} className='box' key={item.key}>
                        <AimCardRow>
                            <AimTilte>{item.title}</AimTilte>
                            <AimContent>{item.content}</AimContent>
                        </AimCardRow>
                    </AimCardHolder>
                ))
            }
        </Fragment>
    )
}
