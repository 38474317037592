import { BiLogoInstagram, BiLogoLinkedin } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";

export const navItems = [
    {
      name: 'Home',
      path: "/",
    },
    {
      name: "About Us",
      path: "/startups",
    },
    {
      name: "Services",
      path: "/service",
    },
    {
      name: "Products",
      path: "/products",
    },
    {
      name: "Career",
      path: "/careers",
    },
    {
      name: "Lets Talk",
      path: "/letstalk",
    },
  ];

  export const secNavItems = [
    {
      name: "About Us",
      path: "/startups",
    },
    {
      name: "Services",
      path: "/service",
    },
    {
      name: "Products",
      path: "/products",
    },
    {
      name: "Career",
      path: "/careers",
    },
    {
      name: "Lets Talk",
      path: "/letstalk",
    },
  ];

  export const socialLinks =  [
    {
      key:'1',
      icon:<BiLogoLinkedin color="white" size={24} />,
      link:'https://www.linkedin.com/company/ideaux-technologies/',
      name:'LinkedIn'
    },
    {
      key:'2',
      icon:<FaFacebook color="white" size={24} />,
      link:'https://www.facebook.com/profile.php?id=100089690263830',
      name:'FaceBook'
    },
    {
      key:'3',
      icon:<BiLogoInstagram color="white" size={24} />,
      link:'https://www.instagram.com/ideauxtech/',
      name:'Instagram'
    },
  ]


  export const socialfooterLinks =  [
    {
      key:'1',
      icon:<BiLogoLinkedin color="black" size={24} />,
      link:'https://www.linkedin.com/company/ideaux-technologies/',
      name:'LinkedIn'
    },
    {
      key:'2',
      icon:<FaFacebook color="black" size={24} />,
      link:'https://www.facebook.com/profile.php?id=100089690263830',
      name:'FaceBook'
    },
    {
      key:'3',
      icon:<BiLogoInstagram color="black" size={24} />,
      link:'https://www.instagram.com/ideauxtech/',
      name:'Instagram'
    },
  ]

  export const mainMenu =[
    {
      name: 'Home',
      path: "/",
    },
    {
      name: "About",
      path: "/startups",
    },
    {
      name: "Services",
      path: "/service",
    },
    {
      name: "Products",
      path: "/products",
    },
    {
      name: "Career",
      path: "/careers",
    },
  ]



  export const subMenuItems =[
    {
      name: 'Clients',
      path: "/#workwith",
    },
    // {
    //   name: "Careers",
    //   path: "/startups",
    // },
    {
      name: "Contact Us",
      path: "/letstalk",
    },
  ]