import React, { useContext, useEffect } from 'react'
import { AddressWrapper, Controls, CustomInput1 } from './style'
import arrow from '../../../Assets/Images/Others/EnterArrow.svg'
import { Col, Form } from 'antd'
import { CustomRow } from '../../../Components/CustomRow'
import { CustomInput } from '../../../Components/CustomInput'
import { CustomCursorContext } from '../../../Components/others/CustomCursor/context/CustomCursorContext'

export const AddressForm = ({ updatedata, reset }) => {

  const [form] = Form.useForm();

  const { type, setType } = useContext(CustomCursorContext);

  const AddressSubmit = () => {
    form.submit();
  }
  const onFinish = (values) => {

    console.log(values,'kkkk');
    updatedata(values);
  };

  useEffect(() => {
    if (reset) {
      form.resetFields();
    }
  }, [reset])

  const onFinishFailed = (errorInfo) => {
  };

  // Email validation function
  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Invalid email format');
  };

  // Phone number validation function
  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/; // Assuming a simple 10-digit phone number for demonstration
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Invalid phone number');
  };

  return (
    <AddressWrapper>

      <Form
        name="AddressForm"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <CustomRow space={[24, 24]}>
          <Col span={24} md={12}>
            <CustomInput name={'username'}
              rules={[
                {
                  required: true,
                  message: 'Name is Required !',
                },
              ]} placeholder={'Your Name'} />
          </Col>

          <Col span={24} md={12}>
            <CustomInput name={'mobilenumber'}
              rules={[
                {
                  required: true,
                  message: 'Invalid Mobile No!',
                },
                {
                  validator: validatePhoneNumber,
                },
              ]} placeholder={'Mobile Number'} />
          </Col>

          <Col span={24} >

            <CustomInput name={'email'}
              rules={[
                {
                  required: true,
                  message: 'Please enter valid email!',
                },
                {
                  validator: validateEmail,
                },
              ]} placeholder={'Enter E - Mail'} />
          </Col>
        </CustomRow>

      </Form>
      <Controls>
        <div>Press<span className='text'>Enter</span><img src={arrow} alt="Enter Arrow" />to send request</div>
      </Controls>
      <button onMouseEnter={() => setType('button')}
        onMouseLeave={() => setType('default')} onClick={AddressSubmit}>Send request</button>
    </AddressWrapper>
  )
}
