import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../Components/CustomRow'
import { Col } from 'antd'
import IconTag from '../../Home/Partials/IconTag'
import Flex from '../../../Components/Flex'
import { Fade } from 'react-awesome-reveal'
import { Container } from '../../../Components/Container'
import { OneProduct, Product, ProductWrapper, ProductCard, Schl, ThirdPart, TwoProduct, ValuableProd, ProductContentDescriptionSpan, ProductCardWrapper } from './Style'
import { GradiantLetter } from '../../../Components/others/GradiantLetter'
import { Footer } from '../../Home/Partials/Footer'
import { NavBar } from '../../../Components/NavBar/NavBar'
import { proData } from '../../CustomData/ProductData'
import gsap from 'gsap'




const Products = () => {

    const [navSticky, setNavSticky] = useState(false)
    const [reviewIndex, setReviewIndex] = useState(1);

    const setSticky = () => {
        if (window.scrollY >= 200) {
            setNavSticky(true)
        } else {
            setNavSticky(false)
        }
    }

    window.addEventListener('scroll', setSticky)


    useEffect(() => {
        const intervalId = setInterval(() => {
            //   setReviewIndex((prevIndex) => (prevIndex + 1) % proData.length + 1 || 1);
            setReviewIndex((prevIndex) => {
                if (prevIndex >= proData.length) {
                    return 1;
                } else {
                    return prevIndex + 1
                }
            });
        }, 5000);

        return () => clearInterval(intervalId);
    }, [proData.length, reviewIndex]);


    return (
        <>
            <NavBar sticky={navSticky} />
            <Fade top>
                <Container>

                    <CustomRow style={{ margin: '10' }} space={[24, 24]}>


                        <Col style={{ marginTop: '5%' }} span={24} md={12}>
                            <Flex>
                                <OneProduct>Our <GradiantLetter size={'64px'}>Products</GradiantLetter></OneProduct>
                            </Flex>
                            <TwoProduct>
                                <p> With a strong focus on creating innovative solutions, our products are designed to perform the way you want it to be. We automate complex business processes, making them easy to use and maintain. </p>
                                <p> You will find our custom software solutions to be highly reliable, cost-effective, and high performing. Our strength lies in creating multiplatform applications, for you to use it from the office desk or in your mobile at your comfort.</p>
                                <p>At IDEAUX, we offer end-to-end IT solutions customized to meet your business needs. Built from deep research, planned in detail and run through rigorous tests to ensure you get what you wanted. Come join our long list of happy customers.</p>
                            </TwoProduct>
                        </Col>

                        <Col span={24} md={12} >
                            <ThirdPart>
                                <IconTag />
                            </ThirdPart>

                        </Col>
                    </CustomRow>


                    <ProductWrapper>
                        <ValuableProd>Our Valuable <GradiantLetter> Products </GradiantLetter></ValuableProd>
                        <div className='holder'>
                            <ProductCardWrapper>
                                {
                                    proData.map((item) => (
                                        <ProductCard key={item.key}>
                                            <div className='title'>
                                                <h1 className={item.key == reviewIndex ? 'active' : ''}>
                                                    {
                                                        item.title.split(' ').map((word, index, array) => {
                                                            return (
                                                                <span className='word' key={index}>{word}</span>
                                                            )
                                                        })}</h1>
                                            </div>

                                            <div className='product__content_container'>
                                                {item.content.split(' ').map((word, index) => {
                                                    return (
                                                        <div className='content' key={index}>
                                                            <ProductContentDescriptionSpan className={item.key == reviewIndex ? 'active' : ''}>
                                                                {word}
                                                            </ProductContentDescriptionSpan>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </ProductCard>
                                    ))
                                }
                            </ProductCardWrapper>
                        </div>

                    </ProductWrapper>
                </Container>
                <Footer />
            </Fade>
        </>
    )
}

export default Products