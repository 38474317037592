import React, { useEffect, useState } from 'react'
import {
    ResTestimonialCard,
    ResTestimonialCardWrapper,
    ReviewSectionHolder,
    TestimonialCardWrapper
} from './Style'
import { testimonialData } from '../../../Assets/Images/Testimonial'

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from '../../../Components/Container';
import { TestimonialCard } from './TestimonialCard';

export const ClientReview = () => {

    const [reviewIndex, setReviewIndex] = useState(1);

    useEffect(() => {
        const intervalId = setInterval(() => {
        //   setReviewIndex((prevIndex) => (prevIndex + 1) % testimonialData.length + 1 || 1);
          setReviewIndex((prevIndex) =>{
            if(prevIndex >= testimonialData.length){
                return 1;
            } else {
                return prevIndex + 1
            }
          });
        }, 6000);
      
        return () => clearInterval(intervalId);
      }, [testimonialData.length,reviewIndex]);

    return (
        <ReviewSectionHolder className='worksection' data-scroll data-scroll-speed={'0.6'} >
            <Container>
                <ResTestimonialCardWrapper>

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        className="mySwiper"
                        modules={[Autoplay, Pagination, Navigation]}
                    >

                        {
                            testimonialData.map((item) => (

                                <SwiperSlide key={item.key}>
                                    <ResTestimonialCard >
                                        {/* <div className='testimonial_carousel_logo_area'>
                                            <img src={item.logo} alt={'Testimonial Data'} />
                                        </div> */}

                                        <div className='testimonial_carousel_description'>
                                            {item.content}
                                        </div>

                                        <div className='testimonial_carousel_client_area'>
                                            <div className='testimonial_carousel_client_img_holder'>
                                                <img src={item.logo} alt="Testimonial Data" />
                                            </div>

                                            <div className='testimonial_carousel_client_text'>
                                                <div className='testimonial_carousel_client_text_name'>
                                                    {item.name}
                                                </div>

                                                {/* <div className='testimonial_carousel_client_text_desgination'>
                                                    {item.des}
                                                </div> */}
                                                {/* <a href="" style={{ textDecoration: 'none' }}>
                                                    <div className='testimonial_carousel_client_text_linkedin'>
                                                        <h1>in</h1>
                                                        <span> Linkedin</span>
                                                    </div>
                                                </a> */}
                                            </div>
                                        </div>
                                    </ResTestimonialCard>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </ResTestimonialCardWrapper>

                {/* <button onClick={()=>setReviewIndex(reviewIndex + 1)}>Add</button>
                <button onClick={()=>setReviewIndex(reviewIndex - 1)}>Remove</button> */}

                <TestimonialCardWrapper>
                    <TestimonialCard data={testimonialData} activeKey={reviewIndex}/>
                </TestimonialCardWrapper>

            </Container>
        </ReviewSectionHolder>
    )
}
