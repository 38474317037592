import React from 'react'
import red from '../../../Assets/Images/Product/red.webp'
import git from '../../../Assets/Images/Product/git.webp'
import yt from '../../../Assets/Images/Product/youtube.webp'
import shopify from '../../../Assets/Images/Product/shopify.webp'
import twitter from '../../../Assets/Images/Product/twitter.webp'
import box from '../../../Assets/Images/Product/box.webp'
import figma from '../../../Assets/Images/Product/figma.webp'
import pinterest from '../../../Assets/Images/Product/pinterest.webp'
import roblox from '../../../Assets/Images/Product/roblox.webp'
import network from '../../../Assets/Images/Product/network.webp'
// import text from '../../../Assets/Images/Product/text.webp'
import styled from 'styled-components'

export const Icons = styled.div`
position: relative;
width: 300px;
height: 440px;
border: 1px solid #e6ffcc;
z-index: 1;
border-radius: 15px;

& span{
    position:absolute;
    bottom:20%;
    left:20px;
    color: #1A1A1A;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 125% */
    letter-spacing: -0.34px;
}
`
export const Red = styled.img`
position: absolute;
z-index:2;
left: 10px;
bottom: 340px;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }
`
export const Git = styled.img`
position: absolute;
left: 150px;
bottom: 350px;
z-index: 9;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }
`

export const Youtube = styled.img`
position: absolute;
left: 200px;
z-index: 11;
top: 25px;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }
`

export const Shopify = styled.img`
position: absolute;
left: 118px;
top: 55px;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }


`

export const Twitter = styled.img`
position: absolute;
top: 100px;
left: 60px;
z-index: 13;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }
`

export const Box = styled.img`
position: absolute;
top: 130px;
left: 85px;
z-index: 9;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }

`
export const Figma = styled.img`
position: absolute;
top: 110px;
left: 160px;
z-index: 8;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }
`

export const Pinterest = styled.img`
position: absolute;
top: 185px;
left: 200px;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }
`
export const Roblox = styled.img`
position: absolute;
top: 375px;
left: 200px;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }

`

export const Network = styled.img`
position: absolute;
top: 352px;
left: 240px;
cursor: pointer;
transition:0.5s;

&:hover{
transform:scale(1.2);
    }



`

export const Text = styled.img`
position: absolute;
top: 310px;
left: 30px;
`



const  IconTag = () => {
    return (
            <Icons>
                <Red src={red} alt={'red'}/>
                <Git src={git} alt={'git'} />
                <Youtube src={yt} alt={'yt'} />
                <Shopify src={shopify} alt={'shopify'} />
                <Twitter src={twitter} alt={'twitter'} />
                <Box src={box} alt={'box'} />
                <Figma src={figma} alt={'figma'} />
                <Pinterest src={pinterest} alt={'pinterest'} />
                <Roblox src={roblox} alt={'roblox'} />
                <Network src={network} alt={'network'} />
                <span>Powers the largest <br/>sites on earth</span>

            </Icons>
    )
}

export default IconTag