export const recruitmentData = [
    // {
    //     key:'1',
    //     name:'Mobile App'
    // },
    // {
    //     key:'2',
    //     name:'Web App'
    // },
    // {
    //     key:'3',
    //     name:'IoT Solution'
    // },
    // {
    //     key:'4',
    //     name:'Data Analytics'
    // },
    // {
    //     key:'5',
    //     name:'Salesforce'
    // }

    {
        key: '1',
        name: 'Web Apps',
    },
    {
        key: '2',
        name: 'Mobile Apps',
    },
    {
        key: '3',
        name: 'Customized Softwares',
    },
    {
        key: '4',
        name: 'Digital Marketing',
    },
    {
        key: '5',
        name: 'Testing',
    },
    {
        key: '6',
        name: 'DevOps',
    },
]

export const timeNeedData = [
    {
        key:'1',
        name:'Within a week'
    },
    {
        key:'2',
        name:'Within two weeks'
    },
    {
        key:'3',
        name:'Within a month'
    },
    {
        key:'4',
        name:'Long term'
    },
]