export const proData = [
    {
        key:1,
        title:'School Management System',
        content:'A School Management System automates administrative tasks, enhances communication, and centralizes data for efficient school operations, benefiting educators, students, and parents.'
    },
    {
        key:2,
        title:'Clinic Software',
        content:'Comprehensive clinic software streamline appointments, patient records, billing, enhance efficiency, organization, and patient care.'
    },
    {
        key:3,
        title:'CRM',
        content:'CRM integrates tech to manage customer interactions, automate sales, and enhance satisfaction, fostering lasting relationships, informed decisions, and business growth.'
    },
    {
        key:4,
        title:'Hi Biller',
        content:'Hi Biller: User-friendly billing software with customizable invoices, automated billing, detailed reporting, global adaptability, secure cloud access, and cost-effective efficiency.'
    },
    {
        key:5,
        title:'ERP',
        content:'ERP integrates business processes, enhancing real-time data sharing, streamlining workflows, reducing redundancies, and optimizing productivity for competitive advantage in industries.'
    },
    {
        key:6,
        title:'HRM',
        content:'HRM aligns personnel activities with organizational goals, optimizing workforce efficiency through recruitment, training, performance evaluation, and employee relations.'
    },
]