import React, { useMemo, useState } from "react";
import Personalnformation from "./Personalnformation";
import EducationInformation from "./EducationInformation";
import SkillsInformation from "./SkillsInformation";
import request from "../../../utils/request";

const ApplyForm = ({ jobDetails, FormExternalClose }) => {
  const [stepCount, setStepCount] = useState(0);
  const [dataSource, setDataSource] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formData = new FormData();

  const UpdateCount = (count) => {
    setStepCount(count);
  };
  

  const updatePersonalData = (values) => {
    setDataSource((prevData) => ({
      ...prevData,
      ...values,
    }));
  };

  const updateEducationData = (educ) => {
    setDataSource((prevData) => ({
      ...prevData,
      ...educ,
    }));
  };

  const updateSkillsData = (skill) => {
    setDataSource((prevData) => ({
      ...prevData,
      ...skill,
    }));
    
    const newValue = {
      ...dataSource,
      ...skill,
    }
    sendData(newValue);  
  };


  const sendData = (Data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("userName", Data?.userName);
    formData.append("emailId", Data?.emailId);
    formData.append("mobileNumber", Data?.mobileNumber);
    formData.append("gender", Data?.gender);
    formData.append("dateofBirth", Data?.dateofBirth);
    formData.append("date", Data?.date);
    formData.append("city", Data?.city);
    formData.append("country", Data?.country);
    formData.append("maritalStatus", Data?.maritalStatus);
    formData.append("address", Data?.address);

    formData.append("education", Data?.education);
    formData.append("branch", Data?.branch);
    formData.append("college", Data?.college);
    formData.append("cgpa", Data?.cgpa);
    formData.append("yearOfPassing", Data?.yearOfPassing);

    formData.append("jobRole", jobDetails?.jobTitle);
    formData.append("position", jobDetails?.position);
    formData.append("hiringId", jobDetails?.hiringId);
    formData.append("departmentId", jobDetails?.applicationId);
    formData.append(
      "salaryExpectations",
      Data?.salaryExpectations
    );
    formData.append("skillDetails", Data?.skillDetails);
    formData.append("workExperience", Data?.workExperience);
    formData.append("coverLetter", Data?.coverLetter);
    formData.append("year", Data?.year ? Data?.year : '');
    formData.append("fileName", Data?.fileName);

    if (Data?.resume && Data?.resume.length > 0) {
      Data?.resume.forEach((file) => {
        formData.append(`resume`, file.originFileObj);
      });
    }

    console.log([...formData.entries()], "fffffffffffffff");

    request
      .post(`${"candidate/save"}`, formData)
      .then(function (response) {
        setDataSource({});
        // FormExternalClose();
        setIsLoading(false);
        setIsSuccess(true);
        console.log(response, "Candidate Added Successfully");
      })
      .catch(function (error) {
        console.error(error, "check");
        setIsLoading(false);
      });
  };

  let pageView;

  switch (stepCount) {
    case 0:
      pageView = (
        <Personalnformation
          count={UpdateCount}
          updatedata={updatePersonalData}
          data={dataSource}
        />
      );
      break;

    case 1:
      pageView = (
        <EducationInformation
          updatedata={updateEducationData}
          count={UpdateCount}
          data={dataSource}
        />
      );
      break;

    case 2:
      pageView = (
        <SkillsInformation
          updatedata={updateSkillsData}
          count={UpdateCount}
          jobDetails={jobDetails}
          data={dataSource}
          loading={isLoading}
          success={isSuccess}
          FormExternalClose={FormExternalClose}
        />
      );
      break;

    default:
      pageView = "Empty";
      break;
  }

  return <div>{pageView}</div>;
};

export default ApplyForm;
