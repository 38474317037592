import React from 'react'
import { Input, Form } from 'antd'
import Label from './Label'
import styled from 'styled-components'
import { THEME } from '../Theme'

const { TextArea } = Input

const StyledItem = styled(Form.Item)`
  > div {
    text-align: left;
  }
  
  .ant-input{
    border:none;
  outline:none;
  display: block;
    width: 100%;
    min-height: 6vh;
    /* line-height: 20px; */
    border-bottom: 2px solid rgba(0,0,0,.3);
    /* margin-top: 5vh; */
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: .005em;
    text-align: left;
    white-space: pre-wrap;
    max-height: 40vh;
    color:#000;
    border-radius: 0;
  }

  & .ant-form-item-label {
    display:block;
    width:100%;
    text-align:start;
    border-color: ${THEME.primary_color} !important;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: 2px solid rgba(0,0,0,.3);
    box-shadow: none;
  }

  .ant-input[disabled]{
    color:black;
  }

  .ant-input:hover {
    border-color: 2px solid rgba(0,0,0,.3) !important;
  }

  & .ant-form-item-label > label > span {
    font-size: 1rem;
    font-weight:600;
  }
`

export const CustomTextArea = ({
    name,
    cols,
    type = 'text',
    placeholder,
    label,
    rules,
    required,
    rows = 4,
    ...rest
}) => {
    return (
        <StyledItem
            colon={false}
            required={false} 
            label={
                label && (
                    <Label required={required} showLock={rest.showLock}>
                        {label}
                    </Label>
                )
            }
            rules={rules}
            name={name}
            {...rest}
        >
            <TextArea
                cols={cols}
                {...rest}
                type={type}
                rows={rows}
                placeholder={placeholder}
                style={{ resize: 'none' }}
            />
        </StyledItem>
    )
}

