import Car1 from '../../Assets/Images/HomeCarousel/Carousel1.webp'
import Car2 from '../../Assets/Images/HomeCarousel/Carousel2.webp'
import Car3 from '../../Assets/Images/HomeCarousel/Carousel3.webp'
import Car4 from '../../Assets/Images/HomeCarousel/Carousel4.webp'
import Car5 from '../../Assets/Images/HomeCarousel/Carousel5.webp'
import Car6 from '../../Assets/Images/HomeCarousel/Carousel6.webp'
import Car7 from '../../Assets/Images/HomeCarousel/Carousel7.webp'
import Car8 from '../../Assets/Images/HomeCarousel/Carousel8.webp'
import { softwareLogo } from '../../Assets/Images/HomeSoftwareLogo'

export const CarouselImg = [
    {
        key: '1',
        name: Car1
    },
    {
        key: '2',
        name: Car2
    },
    {
        key: '3',
        name: Car3
    },
    {
        key: '4',
        name: Car4
    },
    {
        key: '5',
        name: Car5
    },
    {
        key: '6',
        name: Car6
    },
    {
        key: '7',
        name: Car7
    },
    {
        kay: '8',
        name: Car8
    },
]

export const homeSoftwareTitle = [
    {
        key: '1',
        title: 'Web Apps',
    },
    {
        key: '2',
        title: 'Mobile Apps',
    },
    {
        key: '3',
        title: 'Customized Softwares',
    },
    {
        key: '4',
        title: 'Digital Marketing',
    },
    {
        key: '5',
        title: 'Testing',
    },
    {
        key: '6',
        title: 'DevOps',
    },
]

export const homeSoftwareContent = [
    {
        key: '1',
        name: "Web Apps",
        content: 'Have you ever wished for a web application that goes beyond the ordinary, offering a truly unique and tailored experience? At IdeauxTech, We take pride in providing a web application that stands out from the crowd.',
        lists: [
            {
                key: '1',
                title: 'Front-end Development'
            },
            {
                key: '2',
                title: 'Web Application'
            },
            {
                key: '3',
                title: 'Custom Application'
            },
        ]
    },
    {
        key: '2',
        name: "Mobile Apps",
        content: 'Build a state -of-the-art mobile apps that meets ever-changing market demands and elevate customer experience.',
        lists: [
            {
                key: '1',
                title: 'Native IOS App'
            },
            {
                key: '2',
                title: 'Native Android App'
            },
            {
                key: '3',
                title: 'Flutter App'
            },
        ]
    },
    {
        key: '3',
        name: "Customized Softwares",
        content: 'We specialize in providing customized software solutions that are truly unique. Software can make your business stand out. How about something like Our Customized software: Making numbers less scary and more merry.',
        lists: [
            {
                key: '1',
                title: 'Industry specific solutions'
            },
            {
                key: '2',
                title: 'Collaborative Development Process'
            },
            {
                key: '3',
                title: 'Integration Capabilities'
            },
            {
                key: '4',
                title: 'User Interface (UI) Design'
            },
            {
                key: '5',
                title: 'Quality Assurance and Testing'
            },
        ]
    },
    {
        key: '4',
        name: "Digital Marketing",
        content: 'We provide a unique digital marketing approach, going beyond online presence to craft a compelling, tailored digital narrative for your brand.',
        lists: [
            {
                key: '1',
                title: 'Social Media Marketing'
            },
            {
                key: '2',
                title: 'Email Marketing'
            },
            {
                key: '3',
                title: 'Content Marketing'
            },
        ]
    },
    {
        key: '5',
        name: "Testing",
        content: 'Looking to verify if your web & mobile apps are doing what they are supposed to do? Our comprehensive testing process with a detailed report is your answer.',
        lists: [
            {
                key: '1',
                title: 'Manual web testing'
            },
            {
                key: '2',
                title: 'Manual Mobile testing'
            },
            {
                key: '3',
                title: 'Automation testing'
            },
        ]
    },
    {
        key: '6',
        name: "DevOps",
        content: 'We take pride in offering a DevOps solution that goes beyond conventional practices. Our unique approach integrates development and operations, breaking down silos and fostering a culture of collaboration.',
        lists: [
            {
                key: '1',
                title: 'Version controlling'
            },
            {
                key: '2',
                title: 'System testing'
            },
            {
                key: '3',
                title: 'Infra Set-up'
            },
            {
                key: '4',
                title: 'Dynamic Provisioning'
            },
            {
                key: '5',
                title: 'Configuration'
            },
            {
                key: '6',
                title: 'Deployment'
            },
            {
                key: '7',
                title: 'Up-time Monitoring'
            },
        ]
    }
]

export const homeSoftwareIcons = [
    {
        key: '1',
        title: 'Web Apps',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Html,
            },
            {
                key: '2',
                icon: softwareLogo.Css,
            },
            {
                key: '3',
                icon: softwareLogo.React,
            },
            {
                key: '4',
                icon: softwareLogo.Angular,
            },
            {
                key: '5',
                icon: softwareLogo.Laravel,
            },
            {
                key: '6',
                icon: softwareLogo.Flutter,
            }
        ]
    },
    {
        key: '2',
        title: 'Mobile Apps',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Android,
            },
            {
                key: '2',
                icon: softwareLogo.Flutter,
            },
        ]
    },
    {
        key: '3',
        title: 'Customized Softwares',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Java,
            },
            {
                key: '2',
                icon: softwareLogo.Python,
            },
            {
                key: '3',
                icon: softwareLogo.React,
            },
            {
                key: '4',
                icon: softwareLogo.Angular,
            },
            {
                key: '5',
                icon: softwareLogo.Laravel,
            },
            {
                key: '6',
                icon: softwareLogo.Flutter,
            },
            {
                key: '7',
                icon: softwareLogo.Postgrel,
            }
        ]
    },
    {
        key: '4',
        title: 'Digital Marketing',
        lists: [
            {
                key: '3',
                icon: softwareLogo.React,
            },
            {
                key: '4',
                icon: softwareLogo.Angular,
            },
            {
                key: '5',
                icon: softwareLogo.Laravel,
            },
        ]
    },
    {
        key: '5',
        title: 'Testing',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Selenium,
            },
            {
                key: '2',
                icon: softwareLogo.Java,
            },
            {
                key: '3',
                icon: softwareLogo.Postman,
            },
        ]
    },
    {
        key: '6',
        title: 'DevOps',
        lists: [
            {
                key: '1',
                icon: softwareLogo.Amazon,
            },
        ]
    },

]