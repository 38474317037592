import React, { useEffect, useState } from "react";
import { NavBar } from "../../../Components/NavBar/NavBar";
import { Fade } from "react-awesome-reveal";
import { Container } from "../../../Components/Container";
import { CustomRow } from "../../../Components/CustomRow";
import { Col, Switch } from "antd";
import { GradiantLetter } from "../../../Components/others/GradiantLetter";
import { Footer } from "../../Home/Partials/Footer";
import { StyledCareerDetails } from "./style";
import { useParams, useRoutes } from "react-router-dom";
import request from "../../../utils/request";
import axios from "axios";
import { CustomModal } from "../../../Components/CustomModal";
import ApplyForm from "./ApplyForm";

const CareerDetails = () => {
  const [navSticky, setNavSticky] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [getData, setGetData] = useState([]);
  const [dataSource, setdataSource] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const id = useParams();

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const setSticky = () => {
    if (window.scrollY >= 200) {
      setNavSticky(true);
    } else {
      setNavSticky(false);
    }
  };

  window.addEventListener("scroll", setSticky);

  useEffect(() => {
    getCareer();
  }, []);
  useEffect(() => {
    const datass = getData?.find((item) => item?.hiringId == id?.id);
    setdataSource(datass);
  }, [getData]);

  const getCareer = () => {
    const view = "hiring";
    request
      .get("hiring/view", { params: { view } })
      .then(function (response) {
        setGetData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const AddApplyForm = (record) => {
    setModalTitle("Apply Form");
    setModalContent(
      <ApplyForm
        FormExternalClose={handleOk}
        formname={"AddInterviewShedule"}
        jobDetails={record}
      />
    );
    showModal();
  };

  return (
    <>
      <NavBar sticky={navSticky} />
      <div>
        <Container>
          <StyledCareerDetails>
            <div className="leftWrapper">
              <h1>
                <GradiantLetter>{dataSource?.jobTitle}</GradiantLetter>
              </h1>
            </div>
            <Switch
              style={{ float: "inline-end" }}
              disabled={disabled}
              checked={dataSource?.status}
              checkedChildren="Open"
              unCheckedChildren="Closed"
            />

            <div className="content">
              <CustomRow space={[20, 20]}>
                <Col span={24} md={6}>
                  <h2>Job Name</h2>
                </Col>
                <Col span={24} md={18}>
                  <p>{dataSource?.jobTitle}</p>
                </Col>

                <Col span={24} md={6}>
                  <h2>Opening Date</h2>
                </Col>
                <Col span={24} md={18}>
                  <p>{dataSource?.posted}</p>
                </Col>

                <Col span={24} md={6}>
                  <h2>Closing Date</h2>
                </Col>
                <Col span={24} md={18}>
                  <p>{dataSource?.closing}</p>
                </Col>

                <Col span={24} md={6}>
                  <h2>Skills</h2>
                </Col>
                <Col span={24} md={18}>
                  {dataSource?.preferredList?.map((item) => {
                    return (
                      <p key={item?.preferredId}>• {item?.preferredSkills}</p>
                    );
                  })}
                </Col>

                <Col span={24} md={6}>
                  <h2>Description</h2>
                </Col>
                <Col span={24} md={18}>
                  {dataSource?.briefList?.map((item) => {
                    return (
                      <p key={item?.briefId}>• {item?.briefDescription}</p>
                    );
                  })}
                </Col>
                {dataSource?.status && (
                  <Col span={24} md={24}>
                    <div className="btn">
                      <button className="btnn" onClick={() => {AddApplyForm(dataSource)}}>
                        Apply Now
                      </button>
                    </div>
                  </Col>
                )}
              </CustomRow>
            </div>
          </StyledCareerDetails>
          <CustomModal
            isVisible={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            width={800}
            modalTitle={modalTitle}
            modalContent={modalContent}
          />
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default CareerDetails;
