import React, { Fragment } from 'react'
import {
    TestimonialCardHolder,
    TestimonialClientImgHolder,
    TestimonialLinkedIn,
    TestimonialContentDescriptionSpan,
    TestimonialContentTitle,
} from './Style'
export const TestimonialCard = ({ data, activeKey }) => {


    return (
        <Fragment>
            {
                data.map((item,index) => (
                    <TestimonialCardHolder key={index}>
                        <div className='testimonial_section_content_main_image_container'>

                            <TestimonialClientImgHolder count={item.key} order={item.order}>
                                <img src={item.logo} alt="Testimonial Img" className={item.key == activeKey ? 'active' : ''} />
                            </TestimonialClientImgHolder>
                            {/* <a href="">
                                <TestimonialLinkedIn className={item.key == activeKey ? 'active' : ''} >
                                    <h1>in</h1>
                                    <span> Linkedin</span>
                                </TestimonialLinkedIn>
                            </a> */}
                        </div>

                        <div className='testimonial_section_content_main_text'>
                            {/* <div className='testimonial_section_content_main_text_logo_area'>
                                <div className='testimonial_section_content_main_text_logo_image_container'>
                                    <img src={item.logo} alt="Testimonial logo" className={item.key == activeKey ? 'active' : ''} />
                                </div>
                            </div> */}

                            <div className='testimonial_section_content_main_text_title'>
                                <TestimonialContentTitle count={item.key} className={item.key == activeKey ? 'active' : ''}>
                                    {item.name}
                                </TestimonialContentTitle>
                                
                                {/* <span className={`des ${item.key == activeKey ? 'active' : ''}`}>- {item.des}</span> */}
                            </div>

                            <div className='testimonial_section_content_main_text_description'>
                                <div className='testimonial_content_description_div_container'>
                                    {item.content.split(' ').map((word, index, array) => {
                                        return (
                                            <div className='testimonial_content_description_div' key={index}>
                                                <TestimonialContentDescriptionSpan className={item.key == activeKey ? 'active' : ''} count={index}>
                                                    {word}
                                                </TestimonialContentDescriptionSpan>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </TestimonialCardHolder>
                ))
            }

        </Fragment>
    )
}
