import React, { useEffect, useState } from "react";
import { NavBar } from "../../../Components/NavBar/NavBar";
import { Fade } from "react-awesome-reveal";
import { Container } from "../../../Components/Container";
import { Footer } from "../../Home/Partials/Footer";
import { StyledCareers, StyledLine } from "./style";
import { CustomRow } from "../../../Components/CustomRow";
import { Col } from "antd";
import { GradiantLetter } from "../../../Components/others/GradiantLetter";
import image from "../../../Assets/Images/Product/career.png";
import { IoIosArrowRoundForward, IoMdChatbubbles } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import request from "../../../utils/request";

const Careers = () => {
  const [navSticky, setNavSticky] = useState(false);
  const [getData, setGetData] = useState([]);

  const navigate = useNavigate();

  const setSticky = () => {
    if (window.scrollY >= 200) {
      setNavSticky(true);
    } else {
      setNavSticky(false);
    }
  };

  window.addEventListener("scroll", setSticky);

  const handleClick = (value) => {
    navigate(`/careerDetails/${value}`);
  };

  useEffect(() => {
    getCareer();
  }, []);

  const getCareer = () => {
    const view = "hiring";
    request
      .get("hiring/view", { params: { view } })
      .then(function (response) {
        setGetData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const hiring = {
  //   jobTitle: "gdfgd",
  //   email: "gdfgd@gmail.com",
  //   posted: "gdfgd",
  //   closing: "gdfgd",
  //   companyName: "gdfgd",
  //   companyAddress: "gdfgd",
  //   requests: "gdfgd",
  //   briefList: [
  //     {
  //       briefDescription: "sfdsf",
  //     },
  //     {
  //       briefDescription: "sfdsf",
  //     },
  //     {
  //       briefDescription: "sfdsf",
  //     },
  //   ],
  //   preferredList: [
  //     {
  //       preferredSkills: "sfdsf",
  //     },
  //     {
  //       preferredSkills: "sfdsf",
  //     },
  //     {
  //       preferredSkills: "sfdsf",
  //     },
  //   ],
  // };

  return (
    <>
      <NavBar sticky={navSticky} />
      <div>
        <Container>
          <StyledCareers>
            <CustomRow space={[24, 24]}>
              <Col span={24} md={24}>
                <div className="leftWrapper">
                  <h1>
                    <GradiantLetter>Careers</GradiantLetter>
                  </h1>
                </div>
              </Col>
              {getData.map((item) => {
                return (
                  <Col span={24} lg={8} md={24} key={item?.hiringId}>
                    <div className="box">
                      <div className="icons">
                        <IoMdChatbubbles size={30} />
                      </div>
                      <h1>{item?.jobTitle}</h1>
                      <p>{item?.requests}</p>
                      <div
                        style={{
                          display: "flex",
                          margin: "20px 0px",
                          cursor: "pointer",
                        }}
                        onClick={()=> handleClick(item?.hiringId)}
                      >
                        <button>
                          <GradiantLetter>View Details &nbsp;</GradiantLetter>
                        </button>
                        <IoIosArrowRoundForward
                          size={30}
                          style={{ marginTop: "15px" }}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}

              {/* <Col span={24} md={8}>
                <div className="box">
                  <div className="icons">
                    <IoMdChatbubbles size={30} />
                  </div>
                  <h1>Software Developer</h1>
                  <p>
                    We are looking for a Software Developer to build and
                    implement functional program,with minimum three years of
                    experiences as a Software Developer.
                  </p>
                  <div style={{ display: "flex", margin: "20px 0px" }}>
                    <button>
                      <GradiantLetter>View Details &nbsp;</GradiantLetter>
                    </button>
                    <IoIosArrowRoundForward
                      size={30}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                </div>
              </Col>

              <Col span={24} md={8}>
                <div className="box">
                  <div className="icons">
                    <IoMdChatbubbles size={30} />
                  </div>
                  <h1>Software Developer</h1>
                  <p>
                    We are looking for a Software Developer to build and
                    implement functional program,with minimum three years of
                    experiences as a Software Developer.
                  </p>
                  <div style={{ display: "flex", margin: "20px 0px" }}>
                    <button>
                      <GradiantLetter>View Details &nbsp;</GradiantLetter>
                    </button>
                    <IoIosArrowRoundForward
                      size={30}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                </div>
              </Col> */}
            </CustomRow>
          </StyledCareers>
          {/* <StyledLine>
            <CustomRow
              space={[24, 24]}
              style={{
                background: "#2D4979",
                padding: "12px 0px",
              }}
            >
              <Col span={24} md={1}></Col>
              <Col span={24} md={10}>
                <button>Job Search</button>
              </Col>
            </CustomRow>
            <div className="row">
              <CustomRow space={[24, 24]}>
                <Col
                  span={24}
                  md={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="column1">
                    <div style={{ padding: "0px 0px 30px 0px" }}>
                      <p className="heading">{hiring?.jobTitle}</p>
                    </div>
                    <div className="para">
                      <p className="heading">Posted on:</p>
                      <p
                        style={{
                          padding: "10px 0px",
                          fontSize: "16px",
                          paddingLeft: "10px",
                        }}
                      >
                        {hiring?.posted}
                      </p>
                    </div>
                    <div className="para">
                      <p className="heading">Closing on:</p>
                      <p
                        style={{
                          padding: "10px 0px",
                          fontSize: "16px",
                          paddingLeft: "10px",
                        }}
                      >
                        {hiring?.closing}
                      </p>
                    </div>
                    <div className="para">
                      <p className="heading">Contact email:</p>
                      <p
                        style={{
                          padding: "10px 0px",
                          fontSize: "16px",
                          paddingLeft: "10px",
                        }}
                      >
                        {hiring?.email}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col span={24} md={12}>
                  <div style={{ padding: "10px" }}>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        padding: "20px 0px",
                        color: "#000",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>COMPANY NAME</p>
                    </div>
                    <a href="#">{hiring?.companyName}</a>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        padding: "20px 0px",
                        color: "#000",
                      }}
                    >
                      <p style={{ fontSize: "18px" }}>ADDRESS</p>
                    </div>
                    <a href="#">{hiring?.companyAddress}</a>
                  </div>
                </Col>

                <Col span={24} md={24}>
                  <div className="column1">
                    <div className="para">
                      <p className="heading">Brief description :</p>
                      {hiring.briefList.map((item, index) => {
                        return (
                          <ul key={index}>
                            <li>{item.briefDescription}</li>
                          </ul>
                        );
                      })}
                    </div>

                    <div className="para">
                      <p className="heading">Prefferd Skills:</p>
                      {hiring.preferredList.map((item, index) => {
                        return (
                          <ul key={index}>
                            <li>{item.preferredSkills}</li>
                          </ul>
                        );
                      })}

                      <p
                        style={{
                          paddingTop: "20px",
                          fontSize: "18px",
                          color: "#000",
                        }}
                      >
                        {hiring?.requests}
                      </p>
                    </div>
                  </div>
                </Col>
              </CustomRow>
            </div>
          </StyledLine> */}
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Careers;
