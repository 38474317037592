import smileItlogo from './Smilit/smileitlogo.webp'
// import smiltItDir from './Smilit/smilitDir.jpeg'

import brandLogo from './BrandPartner/brand.png'
// import brandDir from './BrandPartner/brandPartnerDir.jpg'

import PugalLogo from './PugalEvent/Pugal Events.webp'


import AariWHLogo from './AariWorld/Aari World.webp'


import BenfieldLogo from './Benfield/benfield.webp'


// import VijayHLogo from './VijayHardware/VijayHardwarelogo.png'
// import VijayHDir from './VijayHardware/VijayHardwareDir.png'


export const testimonialData = [
    {
        key:1,
        name:'Brand Partner',
        des:'Director',
        linkedin:'',
        logo:brandLogo,
        // clientimg:brandDir,
        order:1,
        content:'Been working with them for years. Have contacted them multiple times with different queries; good team with great intellect.'
    },
    {
        key:2,
        name:'SmileIt',
        des:'Manageing Director',
        linkedin:'',
        logo:smileItlogo,
        // clientimg:smiltItDir,
        order:2,
        content:'Their UI/UX designs and their ability to creatively solve challenges from a visual perspective are outstanding.'
    },
    {
        key:3,
        name:'Pugal Event',
        des:'CEO',
        linkedin:'',
        logo:PugalLogo,
        // clientimg:VijayHDir,
        order:1,
        content:'Works like a charm. I love the case of the app and would highly recomended to everyone'
    },
    {
        key:4,
        name:'Aari World',
        des:'CEO',
        linkedin:'',
        logo:AariWHLogo,
        // clientimg:VijayHDir,
        order:2,
        content:'I just wanted to share a quick note and let you know that you guys do a really good job. I am glad I decided to work with you really great how easy your websites are to update and manage. I don’t have any problem at all'
    },
    {
        key:5,
        name:'BenField',
        des:'CEO',
        linkedin:'',
        logo:BenfieldLogo,
        // clientimg:VijayHDir,
        order:1,
        content:'As the small business owner, Ideaux has allowed me to have the confidence I am building a good website at a very reasonable rate.'
    },
 ]

