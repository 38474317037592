import SmileitLogo from '../../Assets/Images/Clients/smileitlogo.webp'
import InsvityLogo from '../../Assets/Images/Clients/insvitylogo.webp'
import ZayitLogo from '../../Assets/Images/Clients/zayitlogo.webp'

import RedLogo from '../../Assets/Images/Clients/brand.webp'
import PugalLogo from '../../Assets/Images/Clients/Pugal Events.webp'
import AariLogo from '../../Assets/Images/Clients/Aari World.webp'
import ShipLogo from '../../Assets/Images/Clients/ship.webp'
import GreenLogo from '../../Assets/Images/Clients/greennet.webp'
import SimsonLogo from '../../Assets/Images/Clients/simson.webp'
import AquaLogo from '../../Assets/Images/Clients/aquacare.webp'
import BenfieldLogo from '../../Assets/Images/Clients/benfield.webp'
import VijayLogo from '../../Assets/Images/Clients/vijay.webp'
import NurseryLogo from '../../Assets/Images/Clients/nursery.webp'
import KkrLogo from '../../Assets/Images/Clients/kkr.webp'
import BsfLogo from '../../Assets/Images/Clients/bsf.webp'



export const logo = [
    {
        key: '1',
        icon: SmileitLogo,
    },
    {
        key: '2',
        icon: PugalLogo,
    },
    {
        key: '3',
        icon: AariLogo,
    },

    {
        key: '4',
        icon: ShipLogo,
    },
    {
        key: '5',
        icon: RedLogo,
    },
    {
        key: '6',
        icon: ZayitLogo,       
    },
    {
        key: '7',
        icon: GreenLogo,       
    },
]


export const logo1 = [
    {
        key: '1',
        icon: InsvityLogo,
    },
    {
        key: '2',
        icon: SimsonLogo,
    },
    {
        key: '3',
        icon: AquaLogo,
    },
    {
        key: '4',
        icon: BenfieldLogo,
    },
    {
        key: '5',
        icon: VijayLogo,
    },
    {
        key: '6',
        icon:NurseryLogo,
    },
    {
        key: '7',
        icon: KkrLogo,
    },
    {
        key: '8',
        icon: BsfLogo,
    },
]




