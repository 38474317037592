import { Col, Form } from "antd";
import React, { useEffect, useState } from "react";
import { CustomRow } from "../../../Components/CustomRow";
import { CustomPageFormTitle } from "../../../Components/CustomPageTitle";
import { CustomSelect } from "../../../Components/CustomSelect";
import { CustomDatePicker } from "../../../Components/CustomDatePicker";
import { CustomTextArea } from "../../../Components/CustomTextArea";
import Flex from "../../../Components/Flex";
import Button from "../../../Components/CustomButton";
import { CustomInput } from "../../../Components/CustomInput";
import dayjs from "dayjs";
import { StyledApply } from "./style";

const Personalnformation = ({ count, updatedata, data }) => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [form] = Form.useForm(); // ----- Define Form

  const handleDate = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setSelectedDate(data?.dateofBirth);
      const dateFormat = "YYYY-MM-DD";
      const DateofBirth = dayjs(data?.dateofBirth).format(dateFormat);

      form.setFieldsValue({
        dateofBirth: dayjs(DateofBirth, dateFormat),
      });
    }
  }, [data]);

  const gender = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Others",
      value: "others",
    },
  ];

  const maritalStatus = [
    {
      label: "Single",
      value: "single",
    },
    {
      label: "Married",
      value: "married",
    },
  ];

  const onFinish = (values) => {
    const newValue = {
      ...values,
      date: formattedDate,
      dateofBirth: selectedDate,
    };
    updatedata(newValue);
    count(1);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <StyledApply>
      <Form
        form={form}
        name={"Personal Information"}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="animate fadeInRight four"
      >
        <CustomRow space={[24, 24]}>
          <Col span={24} md={24}>
            <CustomPageFormTitle Heading={"Personal Information"} />
          </Col>

          <Col span={24} md={12}>
            <CustomInput
              label={"User Name"}
              name={"userName"}
              placeholder={"User Name"}
              rules={[
                {
                  required: true,
                  message: "Please Enter User Name!",
                },
              ]}
            />
          </Col>

          <Col span={24} md={12}>
          <CustomInput
            label={"Email ID"}
            placeholder={"Enter Email ID"}
            name={"emailId"}
            type={"email"}
            rules={[
              {
                required: true,
                message: "Please Enter Email ID !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            name={"mobileNumber"}
            label={"Phone Number"}
            placeholder={"Enter Phone Number"}
            maxLength={10}
            minLength={10}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Mobile Number !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomSelect
            options={gender}
            showSearch={true}
            name={"gender"}
            label={"Gender"}
            placeholder={"Gender"}
            rules={[
              {
                required: true,
                message: "Please Select Gender !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomDatePicker
            label={"Date of Birth"}
            name={"dateofBirth"}
            placeholder={"Date of Birth"}
            onChange={handleDate}
            rules={[
              {
                required: true,
                message: "Please Enter Date Of Birth !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"City"}
            name={"city"}
            rules={[
              {
                required: true,
                message: "Please Enter City !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"Country"}
            name={"country"}
            rules={[
              {
                required: true,
                message: "Please Enter Country !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomSelect
            options={maritalStatus}
            showSearch={true}
            name={"maritalStatus"}
            label={"Marital Status"}
            placeholder={"Marital status"}
            rules={[
              {
                required: true,
                message: "Please Select Marital status !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomTextArea
            label={"Address"}
            name={"address"}
            placeholder={"Enter Address"}
            rules={[
              {
                required: true,
                message: "Please Enter Address !",
              },
            ]}
          />
        </Col>

          <Col
            span={24}
            md={24}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button.Primary text={"Next"} htmlType={"submit"} />
          </Col>
        </CustomRow>
      </Form>
    </StyledApply>
  );
};

export default Personalnformation;
