import React, { Fragment, useContext, useEffect } from 'react'
import { useRef } from 'react'
import { MainCursor } from './Styled'
import { CustomCursorContext } from './context/CustomCursorContext'

export const StyledCursor = () => {
  const mainCursor = useRef(null)
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: null,
    destinationY: null,
    distanceX: 0,   
    distanceY: 0,
    key: -1
  })

  const { type } = useContext(CustomCursorContext)

  useEffect(() => {
    document.addEventListener('mousemove', (event) => {
      const { clientX, clientY } = event

    //   mainCursor.current.style.left = clientX + 'px'
    //   mainCursor.current.style.top = clientY + 'px'

    // console.log(`${clientX}px`);
      mainCursor.current.animate({
          top: `${clientY}px`,
        left: `${clientX}px`,
      },{duration:1000,fill:'forwards'});
    //   mainCursor.current.style.transform = `translate3d(${mouseX - mainCursor.current.clientWidth / 2}px,${mouseY - mainCursor.current.clientHeight / 2}px,0)`
    })

    return () => {}
  }, [])

  return (
    <Fragment>
      <MainCursor ref={mainCursor} className={`${type}`} />
    </Fragment>
  )
}
