import React, { useEffect } from "react";
import { CustomRow } from "../../../Components/CustomRow";
import { Col, Form } from "antd";
import { CustomInput } from "../../../Components/CustomInput";
import { CustomPageFormTitle } from "../../../Components/CustomPageTitle";
import Flex from "../../../Components/Flex";
import Button from "../../../Components/CustomButton";
import { StyledApply } from "./style";

const EducationInformation = ({ count, updatedata, data }) => {

  const [form] = Form.useForm(); // ----- Define Form

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data])

  const onFinish = (values) => {
    updatedata(values);
    count(2);
  };

  const back =()=>{
    count(0);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <StyledApply>
    
    <Form
      form={form}
      name={"Education Information"}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="animate fadeInRight four"
    >
      <CustomRow space={[24, 24]}>
        <Col span={24} md={24}>
          <CustomPageFormTitle Heading={"Education Information"} />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"Education"}
            name={"education"}
            rules={[
              {
                required: true,
                message: "Please Enter Education !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"Branch"}
            name={"branch"}
            rules={[
              {
                required: true,
                message: "Please Enter Branch !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"College"}
            name={"college"}
            rules={[
              {
                required: true,
                message: "Please Enter College !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"Cgpa"}
            name={"cgpa"}
            rules={[
              {
                required: true,
                message: "Please Enter cgpa !",
              },
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <CustomInput
            label={"Year Of Passing"}
            name={"yearOfPassing"}
            rules={[
              {
                required: true,
                message: "Please Enter Year Of Passing !",
              },
            ]}
          />
        </Col>
      </CustomRow>

      <Flex gap={"20px"} center={"true"} margin={"20px 0"}>
        <Button.Danger text={"Back"} onClick={() => back()} />
        <Button.Primary text={"Next"} htmlType={"submit"} />
      </Flex>
    </Form>
    </StyledApply>
  );
};

export default EducationInformation;
