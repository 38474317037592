import styled, { keyframes } from "styled-components";
import { THEME } from "../../Theme";
import { Link } from "react-router-dom";

export const Header = styled.header`
    /* position: fixed;
    top: 0;
    left: 0; */
    z-index: 10;
    position:${props => props.hold ? 'absolute' : 'relative'};
    top:0;
    left:0;
    width: 100%;
    transition: .4s;
    background:transparent;

    border-bottom: 1px solid rgba(255, 255, 255, 0.41);
`

export const TopContainer = styled.div`
  width: 100%;
  padding-right: calc(${THEME.gutter_x} * 0.5);
  padding-left: calc(${THEME.gutter_x} * 0.5);
  position:relative;
  
   @media ${THEME.TABLET} {
      width: 90%; // Set a default width for the container
    margin: 0 auto;
  }
  @media ${THEME.LAPTOPL} {
    width: 80%;
  }
`

export const TopTitle = styled.h2`
    background:red;
    width:90%;
    margin:auto;
    border-radius: 9999px;
    /* border: 1px solid rgba(255, 255, 255, 0.10); */
    background: #0362D7;
    /* box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.15), 0px 20px 25px -5px rgba(0, 0, 0, 0.15); */
    color: #FAFAFA;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    padding: 6.5px 5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    letter-spacing: 3.916px;
    text-transform: uppercase;
    box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.15), 0px 20px 25px -5px rgba(0, 0, 0, 0.15);
    text-align:center;
    @media screen and (max-width:820px){
        display:none
    }
`
export const Nav = styled.nav`
    height: ${THEME.header_height};
    display: flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
    /* backdrop-filter: blur(5px); */
    padding: 0 20px;

    @media ${THEME.TABLET} {
    padding: 0 50px;
    }

`

export const NavLogo = styled.div`
    display: inline-flex;
    align-items: center;
    margin-left:0;
    cursor: pointer;

    @media ${THEME.TABLET} {
        margin-left:3%;
    }
    & img{
        max-width: 150px;
        @media ${THEME.LAPTOPL} {
            max-width: 200px;
        }
    }
`

export const MenuItemsHolder = styled.div`
    /* background:red; */
    display:flex;
    align-items:center;
    justify-content: space-between;
    gap: 20px;
    width: 85%;
    margin: auto;
`
// export const MenuItemsWrapper = styled.div`
//     display:flex;
//     align-items:center;
//     justify-content:space-between;
//     gap:25px;

//     @media screen and (max-width:992px){
//         display:none
//     }

//     & a{
//          color: #1A1A1A;
//         font-family: 'Raleway', sans-serif;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: 21px; /* 150% */
//         letter-spacing: -0.084px;
//         display: inline-block;
//         /* overflow: hidden; */
//         width:max-content;
//         vertical-align: top;
//         text-decoration:none;
//         position:relative;
//        perspective: 600px;
//        perspective-origin: 50% 50%;
//        transition:0.5s;    

//        &  span {
//             display: block;
//             position: absolute;
//             padding: 0 2px;
//             top:0;
//             width:max-content;
//             left:0;
//             transition: all 400ms ease;
//             transform-origin: 50% 0%;
//             transform-style: preserve-3d; 

//             &:nth-child(1){
//                 background:red;
//             }
//             &:nth-child(2){
//                 background:yellow;
//             }
//             &:nth-child(3){
//                 background:gray;
//             }
//             &:nth-child(4){
//                 background:green;
//             }
//          }
//     }

// `

export const MenuItemsWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:25px;

    @media screen and (max-width:992px){
        display:none
    }

    & a {
        color: #1A1A1A;
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px; /* 150% */
        letter-spacing: -0.084px;
        display: inline-block;
        overflow: hidden;
        vertical-align: top;
        text-decoration:none;
       perspective: 600px;
       perspective-origin: 50% 50%;
       transition:0.5s;
       &.active a{
        color:#0362D7;
    } 
    &:last-child{
        position:relative;
         span{
            animation: hoverAnimation 2s infinite ease-in-out;
            }
        &::before{
            position:absolute;
            content:'';
            bottom:0;
            left:50%;
            height:2px;
            width:100%;
            background:${(props) => (props.isActive ? '#0362D7' : '#1A1A1A')};
            transform:translateX(-50%);
            transition:1s ease;
            transform-origin:top;
            perspective:600;
        }

        &:hover::before{
            width: 0;
            background:#fff;
        }
       }
       &:hover span{
           transform: translate3d( 0px, 0px, -30px ) rotateX(90deg );
           color:#fff;
       }
       


       &.active{
        color:#0362D7;
       }

       &  span {
            display: block;
            position: relative;
            padding: 0 2px;
            z-index:10;
            transition: all 400ms ease;
            transform-origin: 50% 0%;
            transform-style: preserve-3d; 
            
            @keyframes hoverAnimation {
                0% {
                    transform: translate3d(0px, 0px, -30px) rotateX(0deg);
                }
                50% {
                    transform: translate3d(0px, 0px, -30px) rotateX(90deg);
                }
                100% {
                    transform: translate3d(0px, 0px, -30px) rotateX(90deg);
                }
            }

            &::after{
                content: attr(data-title);
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                padding: 0 2px;
                transform-origin: 50% 0%;
                transform: translate3d( 0px, 105%, 0px ) rotateX( -90deg );
            }
        }
    }`

export const NavToggleBtn = styled.div`
    width:60px;
    height:60px;
    padding:15px;
    /* background:red; */
    /* z-index: ${THEME.z_100}; */
    display: flex;
    justify-content: center;
    align-items:center;
    transition: .5s;
    cursor: pointer;
    z-index:105;

    & span{
        position:absolute;
        height:2px;
        width:30px;
        background:${props => props.hold ? 'white' : 'black'};
        border-radius:4px;
        transition:0.5s;
        
        @media ${THEME.TABLET}
        {
            background:${props => props.hold ? 'black' : 'black'};
        }

        &:first-child { /* Use :first-child instead of :nth-child(1) */
            transform: translateY(-5px);
        }

        &:last-child { /* Use :first-child instead of :nth-child(1) */
            transform: translateY(5px);
        }

        &:first-child.active { /* Use :first-child instead of :nth-child(1) */
            transform:translateY(0) rotate(45deg);
        }

        &:last-child.active { /* Use :first-child instead of :nth-child(1) */
            transform:translateY(0) rotate(315deg)
        }
    }
    &:hover span{
        background:#fff;
    }
`

export const MenuLink = styled(Link)`
    &.active a{
        color:#0362D7;
    } 
    &:last-child{
        position:relative;
            a span{
                animation: hoverAnimation 2s infinite ease-in-out;
            }
        &::before{
            position:absolute;
            content:'';
            bottom:0;
            height:2px;
            width:100%;
            background:${(props) => (props.isActive ? '#0362D7' : '#1A1A1A')};
        }
       }
`

// Secondary Nav

export const SecMenuItemsWrapper = styled.div`
display:none;

@media ${THEME.MOBILEL}{
   display:flex;
   align-items:center;
   /* justify-content:space-between; */
   gap:40px;
   /* flex:1; */
}


& a {
   color: #1A1A1A;
   font-family: 'Raleway', sans-serif;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 25px; /* 150% */
   letter-spacing: -0.084px;
   display: inline-block;
   overflow: hidden;
   vertical-align: top;
   text-decoration:none;
    perspective: 600px;
    perspective-origin: 50% 50%;
    transition: transform 0.5s;
    position:relative;
    z-index:110;


    &.active{
        color:#0362D7;
    }

    &:hover{
        color:#fff;
    }

  &:last-child{
   position:relative;

    span{
        animation: hoverAnimation 2s infinite ease-in-out;
    }
   &::before{
       position:absolute;
       content:'';
       bottom:0;
       left:50%;
       height:2px;
       width:100%;
       transform:translateX(-50%);
       background:#1A1A1A;
       transition:1s ease;
       transform-origin:top;

       perspective:600;
   }

  
   &:hover::before {
    width: 0;
    background:#fff;
  }
  }

  &:hover span{
      transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
  }

  &  span {
   display: block;
   padding: 0 2px;
  transition: all 400ms ease;
  transform-origin: 50% 0%;
  transform-style: preserve-3d;
    position:relative;
    z-index:110;

  &::after{
       content: attr(data-title);
       display: block;
       position: absolute;
       left: 0;
       top: 0;
       padding: 0 2px;
       transform-origin: 50% 0%;
       transform: translate3d( 0px, 105%, 0px ) rotateX( -90deg );
  }
}
}`

export const SecondaryNavHolder = styled.div`
    border-radius: 56px;
    border: 1px solid #D70303;
    height:60px;
    background: linear-gradient(115deg, #EAEAEA -102.32%, rgba(255, 255, 255, 0.00) 148.39%);
    backdrop-filter: blur(14px);
    /* width:80%; */
    max-width:700px;
    margin:auto;
    display:none;
    align-items:center;
    justify-content:space-between;
    padding:0 30px;
    gap:20px;
    position:sticky;
    top:20px;
    z-index:100;
    /* transition:1s; */
   animation: slide-in--down 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
   transition: opacity 0.3s ease;

  @keyframes slide-in--down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
    &.sticky{
        display:flex;
    }

    &.dark{
        background: linear-gradient(115deg, #000 -102.32%, rgba(255, 255, 255, 0.22) 148.39%);

        ${SecMenuItemsWrapper} a{
            &:last-child::before{
                background:white;
            }
        }

        ${SecMenuItemsWrapper} > a {
            color: white; // Change the color to white for SecMenuItemsWrapper > a
        }

        ${SecMenuItemsWrapper} > a.active {
             color:#0362D7; // Change the color to white for SecMenuItemsWrapper > a
        }

        ${NavToggleBtn} > span{
            background:white;
        }
    }
`

export const SecLogo = styled.a`
    display:inline-block;
    & img{
        width:41px;
    }
`

//  Nav Menu 

export const NavMenu = styled.div`
    position: fixed;
    width: 100%;
    /* left: 0; */
    left: 100%;
    top: 0;
    bottom: 0;
    z-index: 10000;
    transition: 1.2s;
    height: 100vh;  
    display:flex;
    flex-direction:column-reverse;
    overflow:hidden;
    @media ${THEME.LAPTOP} {
        flex-direction:row
    }

    &.active{
        left:0;
        height: 100vh;  
        overflow: hidden;
        transition: 1.5s;

    }
`

export const ListWrapper = styled.div`
    width:100%;
    background:${THEME.black};
    height:70vh;
    display:flex;
    flex-direction: column;
    padding: 0px 3vw;
    justify-content: flex-end;
    @media ${THEME.LAPTOP} {
        display: flex;
        height: 100%;
        width: 50%;
        align-items: center;
        justify-content: center;
    }
`

export const TopNav = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 20px;
    height:60px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;

    img{
        width:150px;
    }

    @media ${THEME.LAPTOP} {
        top:2rem;
        padding: 0 40px;
        justify-content: flex-end;

        & img{
            display:none
        }
    }
`

export const NavCloseBtn = styled.div`
    width:60px;
    height:60px;
    padding:15px;
    /* background:red; */
    /* z-index: ${THEME.z_100}; */
    display: flex;
    justify-content: center;
    align-items:center;
    transition: .5s;
    cursor: pointer;
    z-index:105;
    & span{
        position:absolute;
        height:2px;
        width:30px;
        background:white;
        border-radius:4px;
        transition:0.5s;

        &:first-child { /* Use :first-child instead of :nth-child(1) */
            transform: translateY(-5px);
        }

        &:last-child { /* Use :first-child instead of :nth-child(1) */
            transform: translateY(5px);
        }

        &:first-child.active { /* Use :first-child instead of :nth-child(1) */
            transform:translateY(0) rotate(45deg);
        }

        &:last-child.active { /* Use :first-child instead of :nth-child(1) */
            transform:translateY(0) rotate(315deg)
        }
    }
`
export const BottomNav = styled.div`
    height:min-content;

    @media ${THEME.LAPTOP}{
        height:100%;
    }
`

export const BottomNavWrapper = styled.div`
    height:100%;

     @media ${THEME.LAPTOP}{
        height:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        flex-direction:column;
    }
`

export const BottomNavHolder = styled.div`
    display: flex;
    width: 100%;
    height: max-content;
    margin-bottom: 5.5vh;
    align-items: flex-end;

    @media ${THEME.LAPTOP}{
        justify-content: space-between;
        margin-top:15vh
    }
    & .hamburger_nav_main_links_container{
        display: flex;
        width: max-content;
        height: max-content;
        flex-direction: column;
        align-items: flex-start;

        a{
            text-decoration:none;
        }
        a.links {
                display: flex;
                font-family: 'Raleway', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 37px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                margin-top: 3vh;
                text-decoration:none;

            @media ${THEME.LAPTOP}{
                font-size: 38px;
                /* line-height: 48px; */
                padding:20px;
            }
        }
    }

    & .hamburger_nav_side_links_container{
        display: flex;
        width: max-content;
        height: max-content;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20vw;
    
        @media ${THEME.LAPTOP}{
            margin-left:0;
        }
        a{
            text-decoration:none;
        }
        a.links {
            display: flex;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;
            margin-top: 2vh;
            text-decoration:none;

            @media ${THEME.LAPTOP}{
                font-size: 24px;
                line-height: 36px;
                padding:10px;
            }
        }
    }
`

export const BottomLinkHolder = styled.div`
        display: flex;
        width: 100%;
        height: max-content;
        align-items: center;
        margin-bottom: 5.5vh;

        a{
            text-decoration:none;
            .icon{
                margin-right: 10vw;
                display:inline-block;
                @media ${THEME.LAPTOP} {
                    display:none;
                }
            }

            .name{
                display:none;
                @media ${THEME.LAPTOP} {
                    display:inline-block;
                    color: #fff;
                    margin: 0 15px;
                    padding: 10px;
                    font-size: 20px;
                    font-family: 'Raleway', sans-serif;
                    font-weight: 500;
                    line-height: 32px;
                }
            }
        }
`

export const TalkWrapper = styled.div`
    width:100%;
    background:${THEME.white};
    height:30vh;
    position:relative;

    @media ${THEME.LAPTOP} {
        display:flex;
        height:100%;
        width:50%;
        align-items:center;
        flex-direction:column;
        justify-content:space-between;
    }

    & h5{
        position:absolute;
        bottom:5%;
        color: #505050;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        /* font-size: 36px; */
        font-style: normal;
        font-weight: 400;
        line-height: 45.36px; /* 126% */
        display:none;

        @media ${THEME.LAPTOP} {
            display:block;
        }
    }
`

export const TalkHolder = styled.div`
    width:100%;
    flex:1;
    height:100%;
    display:flex;
    align-items:center;
    /* padding-top:270px; */
    justify-content:center;
    flex-direction:column;
    gap:20px;

    & h1{
        color: #000;
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-size: 3vh;
        font-weight: 600;
        line-height: 7vh;
         /* font-size: 74px;
        font-weight: 600;
        line-height: 45.36px; 61.297% */

        @media ${THEME.LAPTOP} {
        font-size: 5vh;
        line-height: 7vh;
        }
    }
    & a{
        font-family: 'Raleway', sans-serif;
        font-size: 4vh;
        font-weight: 300;
        line-height: 7vh;
        text-decoration:none;
        /* font-size: 74px;
        font-style: normal;
        font-weight: 300; */
        /* line-height: 45.36px; 61.297% */
        position:relative;
        @media ${THEME.LAPTOP} {
        font-size: 6vh;
        line-height: 7vh;
        }

     &::before{
        content:'';
        position: absolute;
        height:3px;
        width:100%;
        background:#e3e3e3;
        bottom: -8px;
        left:50%;
        transform:translateX(-50%);
        transition:0.5s ease;
     }

     &:hover::before{
        width:0;
     }
    }
`