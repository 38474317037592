import React from "react";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "../Home/Partials/HomePage";
import Products from "../Products/Partials/Products";
import Startups from "../About/Partials/Startups";
import { LetsTalk } from "../LetsTalk/Partials/LetsTalk";
import ServiceNew from "../Services/Partials/ServiceNew";
import PageNotFound from "../PageNotFound/PageNotFound";
import Careers from "../Careers/Partials/Careers";
import CareerDetails from "../Careers/Partials/CareerDetails";


const AppRouter = () => {

	return (
		<Routes>
			<Route path="*" element={<PageNotFound />} />
			<Route path="/" element={<HomePage />} />
			<Route path="/startups" element={<Startups />} />  // About Us
			<Route path="/letstalk" element={<LetsTalk />} />
			<Route path="/products" element={<Products />} />
			<Route path="/careers" element={<Careers />} />
			<Route path="/service" element={<ServiceNew />} />
			<Route path="/careerDetails/:id" element={<CareerDetails />} />
		</Routes>
	);
}

export default AppRouter;
