import { DatePicker as AntdDatePicker, Form } from 'antd'
import styled from 'styled-components'
import Label from './Label'
import { Fragment } from 'react';


const dateFormat = 'YYYY-MM-DD';

const { Item } = Form

const StyledItem = styled(Item)`
  > div {
    width: 100%;
    text-align: left;
  }

  border-radius: 0.4rem;
  margin-bottom: 5px !important;

  & .ant-form-item-label {
    display:block;
    width:100%;
    text-align:start;
  }

  & .ant-picker {
    /* border: 1px solid #d9d9d9 !important;
    border-radius: 0.4rem; */
    border-radius: 0px;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,.3);
  } 

  & .ant-form-item-label > label > span {
    font-weight: 600 !important;
    position: relative;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.01em;
  }

  .ant-picker-focused.ant-picker {
    border-color: #1677ff;
    box-shadow: none !important;
    outline: 0;
}
  
`
const AntdDatePickerStyle = styled(AntdDatePicker)`
    width:100%;
    height:40px;

    ::placeholder {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  :hover {
    /* border:1px solid #b3d8ff; */
  }

  :not(.ant-input-affix-wrapper-disabled):hover {
    /* border:1px solid #b3d8ff !important; */
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-right-width: 0px !important;
  }

  &.ant-input{
    font-weight:500 !important;
    padding: 8px 11px !important;
  }

  :focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    box-shadow: 0 0 0 2px rgba(87,168,233, .2);
  }

`
export const CustomDatePicker = ({
  initialValue,
  label,
  type,
  name,
  rules,
  onChange,
  placeholder,
  required,
  disabled,
  width,
  minwidth,
  height,
  value,
  showSearch,
  marginRight,
  labelStyle,
  defaultValue,
  optional,
  format,
  picker,
  noStyle = undefined,
  ...rest
}) => {

  const isRequired = Array.isArray(rules) && rules.some(rule => rule.required);
  const handleChange = (date, dateString) => {
    // onChange(dayjs(dateString).format('YYYY-MM-DD'));
    onChange(dateString);
  };

  return (
    <StyledItem
      style={{
        width: width,
        marginRight: marginRight,
        minwidth: minwidth
      }}
      rules={rules}
      noStyle={noStyle}
      name={name}
      colon={false}
      required={false}
      label={
        label && (
          <Fragment>
            <Label required={required} labelStyle={labelStyle}>
              {label} {isRequired && <span style={{ color: 'red' }}>*</span>} {optional}
            </Label>
          </Fragment>
        )
      }
    >
      <AntdDatePickerStyle disabled={disabled} name={name} picker={picker} value={value} onChange={handleChange} />
    </StyledItem>
  )
}