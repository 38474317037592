import React, { useEffect, useRef } from 'react'
import { Controls, DescriptionWrapper, InfoMark } from './style'
import { Form } from 'antd'
import { useState } from 'react';
import styled from 'styled-components';
import arrow from '../../../Assets/Images/Others/EnterArrow.svg'
import uparrow from '../../../Assets/Images/Others/UpArrow.svg'
import { THEME } from '../../../Theme';


const TextArea = styled.textarea`
  /* padding: 2px; */
  resize: none;
  overflow-y: hidden;
  border:none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.76);
  outline:none;
  display: block;
    width: 100%;
    min-height: 6vh;
    /* line-height: 20px; */
    border: none;
    border-bottom: 2px solid rgba(0,0,0,.3);
    /* margin-top: 5vh; */
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: .005em;
    text-align: left;
    white-space: pre-wrap;
    max-height: 40vh;
    color:#000;

    @media ${THEME.TABLET} {
        font-size: 20px;
        line-height: 32px;
    }
  &:focus{
    box-shadow:none;
    border-color:rgba(0, 0, 0, 0.76);
  }
  &::placeholder{
    color: #A1A1A1;
    font-family: 'Raleway', sans-serif;
    font-size:16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 160% */
    letter-spacing: 0.1px;

    @media ${THEME.TABLET} {
        font-size: 20px;
        line-height: 32px;
    }
  }
`;

export const ProjectDescription = ({count, updatedata}) => {

    const [val, setVal] = useState("svsd svsdsvsd svsdsvsd svsdsvsd svsd");
    const [form] = Form.useForm();
    const [btnShow, setBtnShow] = useState(false)

    // const { TextArea } = Input;

    const onFinish = (values) => {
        updatedata(values);
        count(2)
    };

    const onFinishFailed = (errorInfo) => {
    };

    const textAreaRef = useRef(null);

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };

    useEffect(resizeTextArea, [val]);

    const onChange = e => {
        setVal(e.target.value);
        if (e.target.value != '') {
            setBtnShow(true)
        } else {
            setBtnShow(false)
        }
    };

    const SubmitDescription = () => {
        form.submit();
    }

    return (
        <DescriptionWrapper>
            <h1>Describe project</h1>

            <div>
                <Form
                    name="description"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label=""
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                    >
                        <TextArea ref={textAreaRef} onChange={onChange} placeholder='Enter here' />
                    </Form.Item>

                </Form>
                <InfoMark><span className='text'>Shift<img src={uparrow} alt="shiftKey" /></span><span className='text'>+ Enter <img src={arrow} alt="Enter Arrow" /></span><span>to make a line break</span></InfoMark>
            </div>
            {
                btnShow && (
                    <Controls>
                        <span className='btn' onClick={SubmitDescription}>ok</span>
                        <div>Press<span className='text'>Enter</span><img src={arrow} alt="Enter Arrow" />to continue</div>
                    </Controls>
                )
            }

        </DescriptionWrapper>
    )
}
