import { AimCardHolder,FactCardRow, FactContent } from './Style'
import React, { Fragment } from 'react'

export const FactsCard = ({data}) => {
  return (
    <Fragment>
        {
            data.map((item) => (
                <AimCardHolder order={item.order} className='facts' key={item.key}>
                    <FactCardRow>
                        <FactContent>{item.content}</FactContent>
                    </FactCardRow>
                </AimCardHolder>
            ))
        }
    </Fragment>
  )
}
