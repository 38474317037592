import styled, { keyframes } from "styled-components";
import { THEME } from "../../../Theme";
import Rect from '../../../Assets/Images/Others/Rect.svg'
import { Swiper, SwiperSlide } from "swiper/react";

export const HomeFirstSection = styled.div`
`

export const HomeFirstSectionPara = styled.div`
   /* padding-left:0; */
   width:95%;
   margin:auto;
   margin-top:50px;

    @media ${THEME.TABLET} {
      
      width:90%;
      
      /* padding-left:30px; */
    }
  & span{
    font-weight:700;
  }
  .weare {
    color: #0362D7;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  text-transform: capitalize;

  @media ${THEME.MOBILEL} {
  font-size: 24px;
      }
  }

.home__content{
  color:#222;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
  text-transform: capitalize;

    @media ${THEME.MOBILEL} {
      font-size: 24px;
    line-height: 35px; 
          }
    }
`
const gradientBack = keyframes`
    0% { background-position:0 85%; }
    50%{ background-position:100% 20%; }
    100% { background-position: 0 85%; }
`;

//    Home Carousel 
export const HomeCarouselHolder = styled.div`
  margin:100px 0;
  position:relative;

  /* &::before{
    position:absolute;
    content:'';
    height:400px;
    width:100%;
    top:50%;
    transform:translateY(-50%);
    background: linear-gradient(to right, #0E73CC, #624BBB,#FF455D, #F35815,#FED54A);
    background-size:600% 600%;
    animation: ${gradientBack} 10s ease-in-out infinite; 
    display: flex;
    flex-wrap: wrap; 
    overflow: hidden;
    justify-content: center;
  } */
`

export const SwiperContainer = styled.div`
  position:absolute;
  content:'';
  background:transparent;
  top: 0;
  left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 10;
    &::before{
      position:absolute;
  content:'';
    background:transparent;
    top: 0;
    left: 50%;
    width: 80%;
    height: 100%;
    transform: translateX(-50%);
    z-index: 10;
    }
`

export const HomeCarouselBackLayer = styled.div`
    position:absolute;
    content:'';
    height:400px;
    width:100%;
    top:50%;
    transform:translateY(-50%);
    background: linear-gradient(to right, #0E73CC, #624BBB,#FF455D, #F35815,#FED54A);
    background-size:600% 600%;
    animation: ${gradientBack} 10s ease-in-out infinite; 
    display: flex;
    flex-wrap: wrap; 
    overflow: hidden;
    gap:4px;
    justify-content: center;
   
  
    &::before {
    content: ''; // Ensure to include this line
    background-image: url(${Rect});
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; // Set the height to cover the entire container
    /* background-size: cover; // Adjust as needed */
    background-position: center; // Adjust as needed
  }
`

export const HomeCarouselwrapper = styled.div`
  max-width:100%;
  height:560px;
  position:relative;
  margin:auto;
  z-index:2;
  border-radius: 12px;
  display:grid;
  place-items:center;
  background: linear-gradient(180deg, #EBEBEB 0%, #E1E1E1 100%);
  box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);
  @media ${THEME.MOBILEL} {
    max-width: 560px;
  }

  @media ${THEME.TABLET} {
    max-width: 758px;
  }

  @media ${THEME.LAPTOP} {
    max-width: 900px;
  }

  &::before{
    content:'';
    position:absolute;
    width:calc(100% + 64px);
    border-radius: 42px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(255, 255, 255, 0.05);
    /* background:red; */
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.20) inset;
    backdrop-filter: blur(2px);
    /* z-index:1; */
    height:calc(100% + 64px);
    top:-32px;
    left:-32px;
  }
`

export const CarousalHolder = styled.div`
  width:calc(100% - 34px);
  height:calc(100% - 34px);
  background:#fff;
  overflow:hidden;
  border-radius: 12px;
  position:absolute;
  padding: 0;
  @media screen and (max-width:850px) {
    padding: 0 100px;
  }

  @media screen and (max-width:560px) {
    padding: 0 5px;
  }

`

export const  SwiperCardHolderOutline = styled.div`
  position:absolute;
  height:100%;
  width:calc(100% + 400px);
  top:0;
  left:-200px;

  @media screen and (max-width:768px) {
    left:0;
    width:100%;
  }
`

export const SwiperTitle =styled.h1`
  color: #3D3D3D;
  padding-top: 48px;
  padding-bottom:30px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; 
  letter-spacing: 1px;
  text-transform: capitalize;


  @media ${THEME.TABLET} {
    font-size: 32px;
    padding-bottom:50px;
  }
`

export const SwiperSildeWrapper = styled(Swiper)`
  & .swiper-wrapper{
    display:flex;
    align-items:center; 
    padding:40px 0;
  }
`
export const SwiperSildeHolder = styled(SwiperSlide)`
  /* border-radius:12px;
  /* opacity:0.5; */
  /* width:460px !important; */
  transform:scale(1);
  display:flex;
  align-items:center;
  height:200px; 
  opacity:0.8;
  transition:transform 0.5s;
  &.swiper-slide-active{
    height:200px;
    /* width:560px !important; */
    /* opacity:1; */
  transform:scale(1);
  opacity:1;

    @media ${THEME.TABLET} {
      transform:scale(1.1);
    }
  }
  & div {
    background: radial-gradient(233.07% 124.76% at -13.88% 59.15%, #0E73CC 6.35%, #624BBB 29.85%, #FF455D 44.56%, #F35815 56.07%, #FED54A 100%);
    position:relative;
    height:100%;
    width:100%;
    border-radius:12px;
    overflow:hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    img{
      position: absolute;
      inset:0;
      object-fit:fill;
      /* top:0;
      left:0; */
      height:100%;
      width:100%;
      border-radius:12px;
      /* object-fit:fill; */
    }
  }
`
export const HomeCarouselBottomWrapper = styled.div`
  display:flex;
  align-items:center;
  gap:20px;
  justify-content:center;

  & span.count{
    color: #0362D7;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px; /* 150% */
    @media ${THEME.MOBILEL} {
    font-size: 36px;
    }
  }

  & span.text{
    color: rgba(40, 40, 40, 0.60);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 150% */
      @media ${THEME.MOBILEL} {
        font-size: 18px;
      }
  }
`
export const HomeAni = styled.div`
width: 100%;
height: 500px;
margin-top:140px;
`

export const HomeAnime = styled.div`
height: 630px;
/* position: absolute; */
margin: auto;
max-width: 76%;

background-color: rgba(255, 255, 255, 0.5);
backdrop-filter: blur(10px);
@media (max-width: 768px) {
  /* position: absolute; */
  /* right: 0; */
  /* left: 9px; */
  /* width: 355px; */
  }

  
  @media (min-width: 499px) and (max-width: 993px) {
     /* position: absolute;
    left: 9px;
    width: 749px; */

   

}

  

`
export const HomeAnimee = styled.div`
    width: 100%;
    height: 646px;
    margin: auto;
    /* padding: 25px; */
    /* max-width: 100%; */
    position: absolute;
    /* left: 25px; */
    bottom: 60px;
    border: 11px solid #EBEBEB;
    border-radius: 10px;
    background-color: #ffff;
    @media (max-width: 768px) {
      position: absolute;
    margin: auto;
  }
`

export const ReactLogos = styled.div`

@media (max-width: 768px) {
display: none;
  }

`

export const SecondPart = styled.div`
margin-top:150px;
animation: colorAnimation 2s infinite;
`;

export const ThirdPart = styled.div`
margin-top:150px;
margin-left: 110px;

`;

export const UserFriendly = styled.div`
  margin-top: 100px;
  font-weight: 600;
  font-size: 76px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }


`;








export const DeviceImage = styled.img`
height:100vh;
width:100%;
@media (max-width: 768px) {
    height:500px;
    margin-left: -6px;
  }

  
  @media (min-width: 499px) and (max-width: 993px) {
    height:364px;
  }

`


export const FourImage = styled.img`
height:95%;
width:95%;

@media (max-width: 768px) {
   margin-top: 20px;
   width:94%;
    margin-left:1px;
  }

`;

export const Mobile = styled.img`
width:94%;
height: 92%;
@media (max-width: 768px) {
  margin-top: 20px;
  width:94%;
    margin-left: 1px;
  }

`;

export const UserFriendlyone = styled.div`
margin-top:30px;
`;

export const Concepts = styled.img`
height:98%;
width:98%;
@media (max-width: 768px) {
  /* margin-top: 30px; */
  width:95%;
    margin-left: 1px;
  }

`;

export const Music = styled.img`
height:95%;
width:95%;
@media (max-width: 768px) {
  margin-top: 10px;
  width:95%;
  margin-left: -1px;
  }
`

export const Playlist = styled.img`
height:95%;
width:90%;
@media (max-width: 768px) {
  margin-top: 30px;
  width:95%;
  margin-left: -1px;
  }

  
    @media (min-width: 499px) and (max-width: 993px) {
    margin-top: 12px;
  width:95%;
  margin-left: -1px;
}


`

export const Phone = styled.img`
/* height:98vh; */
height: 64vh;
margin-top: 128px;
width:95%;
@media (max-width: 768px) {
  margin-top: 30px;
  height:98%;
  }

      @media (min-width: 499px) and (max-width: 993px) {
    margin-top: 43px;
  width:95%;
  margin-left: -1px;
}

  
`
export const SlideTag = styled.div`
transform: scale(0.7);
transition: transform 300ms;
opacity: 0.5;

& img {
  
   height:12rem;
  margin: 0 auto; 
}

&.active {
  transform: scale(1.1);
  opacity: 1;
}
@media (max-width: 768px) {
  & img {
    
    height:6rem;
  }

  
}

@media (max-width:400px) {
  & img {
    
    height:5rem;
  }

  
}
`;

const gradientLetter = keyframes`
    0% { filter: hue-rotate(0deg); }
    100% { filter: hue-rotate(360deg); }
`;

export const FooterWrapper =styled.section`
  background:#fff;
  height:100vh;
  display:flex;
  flex-direction:column;

  & div.talkHolder{
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    gap:50px;

    a.goto{
      text-decoration:none;
    }

    h1{
      color: #242424;
      font-family: 'Raleway', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 45px; /* 133.333% */
      letter-spacing: -1.04px;
      text-align:center;
      width:80%;
      
      @media ${THEME.LAPTOP} {
        font-size: 45px;
        line-height:60px;
      }
    }
    span{
      font-family: 'Raleway', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 50px; /* 294.75% */
      letter-spacing: -1.426px;
      background: linear-gradient(90deg, #E4DD32 0%, #FF9432 0%, #E43F3F 26.95%, #F2438C 84.42%, #E465D8 100%, #6B2BBD 100%, #203DA4 100%, #209CA4 100%, #38CF75 100%, #E4DD32 100%, #E4DD32 100%, #E4DD32 100%, #E4DD32 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border-radius: 94px;
      border: 1px solid #EF66B4;
      padding:15px 30px;
      cursor: pointer;
      animation: ${gradientLetter} 3s ease-in-out infinite;
      transition: .5s ease-in-out;
      
      &:hover{
      background: linear-gradient(90deg, #E4DD32 0%, #FF9432 0%, #E43F3F 26.95%, #F2438C 84.42%, #E465D8 100%, #6B2BBD 100%, #203DA4 100%, #209CA4 100%, #38CF75 100%, #E4DD32 100%, #E4DD32 100%, #E4DD32 100%, #E4DD32 100%);
      -webkit-text-fill-color: #fff;
        animation: ${gradientLetter} 3s ease-in-out infinite;
      }

      @media ${THEME.LAPTOP} {
        line-height:70px;
        font-size:48px;
        padding:20px 40px;
      }
    }
  }

  & div.footercontent{
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-direction:column;
    gap:30px;
    padding: 50px 10px;

    @media ${THEME.MOBILEL} {
      flex-direction:row;
    }

    & .logocontainer{
        display:flex;
        flex-direction:column;
        gap:20px;
        @media ${THEME.TABLET} {
          gap:30px;
        }
      img{
        max-width:150px;

        @media ${THEME.TABLET} {
        max-width:250px;
        }
      }
      p{
        color: rgba(0, 0, 0, 0.30);
    font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.175px;
      }
    }

    & .linkcontainer{
      display:flex;
      flex-direction:column;
      gap:30px;

      .socialLinks{
        display:flex;
        align-items:center;
        justify-content: space-evenly;

        a{
          position: relative;
          z-index: 15;
          & svg{
            transition:.5s;
          }
          &:hover svg{
            fill:#fff;
          }
        }
      }
      .termsholder{
        display:flex;
        align-items:center;
        justify-content:space-between;
        gap:30px;
        
        p{
          color: rgba(0, 0, 0, 0.30);
          text-align: right;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.175px;
        }
      }
    }
  }
`




// ---------  What We do 
export const HomeSoftwareContainer = styled.div`
`

export const HomeSoftwareWrapper =styled.div`
  display:flex;
  gap:50px;
  flex-direction:column;
  margin:50px 0;
`

export const HomeSoftwareContentHolder= styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:center;
  gap:20px;
  flex-direction:column;
  position:relative;

  @media ${THEME.TABLET} {
    flex-direction:row;
  }
`

export const HomeSoftwareRow =styled.div`
  width:80%; 
  margin: auto;
  height:min-content;

  &.homesoftware{
    width:80%;

    @media ${THEME.TABLET} {
      width:60%; 
      margin: 0;
    }
  }

  @media ${THEME.TABLET} {
    width:40%; 
    margin: 0;
  }
`
const gradient = keyframes`
0% { filter: hue-rotate(0deg); }
100% { filter: hue-rotate(360deg); }
`; 

export const HomeSoftwareTitle = styled.div`
  display:none;
  @media ${THEME.TABLET} {
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    gap:8px;
    margin: auto;
    gap: 8px;
    max-width: 80%;
  }

  & h1{
    color: #6D6D6D;
    font-family: 'Raleway', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 46px; /* 193.75% */
    letter-spacing: -1.61px;
    cursor: pointer;  
    transition: 0.2s ease;
    /* transition-delay:0s; */

    &.active{
    background: linear-gradient(94deg, #9E00FF 39.33%, #FAFF00 121.02%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
      animation: ${gradient} 3s ease-in-out infinite;
    font-weight: 700;
    /* transition-delay:.2s; */
    }
  }
`

// export const ResHomeSoftwareTitle = styled.div`
//     display:flex;
//     align-items:flex-start;
//     flex-direction:column;
//     gap:8px;
//     margin: auto;
//     gap: 8px;
//     margin-bottom:20px;
//     @media ${THEME.TABLET} {
//       display:none;
//     }

// & h1{
//   color: #000;
//   font-family: 'Raleway', sans-serif;
//   font-size: 25px;
//   font-style: normal;
//   background:#fff;
//   font-weight: 600;
//   line-height: 40px; /* 167.08% */
//   letter-spacing: 0.4px;
// }
// `
export const HomeSoftwareContentWrapper = styled.section`
  display:flex;
  flex-direction:column;
  gap:50px;
  position:relative;
`

export const HomeSoftwareStickyHeader = styled.div`
  position:absolute;
  /* background:red; */
  width:200px;
  height:20px;
  top:-60px;
  right:0;
    @media ${THEME.TABLET} {
      width:300px;
      top:20px;
      right:0;
    }

  h1{
    color: #2B2B2B;
    text-align: right;
    font-family: 'Raleway', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 111.111% */
    letter-spacing: 1px;
    text-transform: capitalize;
    @media ${THEME.TABLET} {
    font-size: 36px;
    }
  }

  p{
    color: #2B2B2B;
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    @media ${THEME.TABLET} {
    font-size: 16px;
    }
  }
`

export const HomeSoftwareIconHolder= styled.div`
  position:absolute;
  left:0;
  /* top:580px; */
  top:calc(100vh - 200px);
  border-radius: 16px;
  border: 1px solid #FFD600;
  background: #F0F0F0;
  z-index:5;
  backdrop-filter: blur(14px);
  /* width: 1186px; */
  width:100%;
  height: 60px;
  flex-shrink: 0;
  overflow:hidden;


  @media ${THEME.MOBILEL} {
    height: 84px;
    }

  & div.icon__holder{
    /* background:red; */
    position:absolute;
    height:60px;
    width:100%;
    top:110%;
    display:flex;
    visibility:hidden;
    opacity: 0;
    align-items:center;
    justify-content:space-evenly;
    z-index:5;
    transition:0.5s ease-in-out;
    /* width:100%;
    height:100%; */
  }

  & div.active{
      z-index:10; 
      inset: 0;
      height: 100%;
    visibility:visible;
    opacity: 1;
    }

  & img{
    transition:2s;
    /* padding: 0 20px; */
    height:auto;
    width:25px;

    @media ${THEME.MOBILEL} {
      width:50px;
    }
  }
`

export const HomeSoftewareCard = styled.div`
  display:flex;
  flex-direction:column;
    padding: 40px 0px;

    @media ${THEME.TABLET} {
        padding: 21vh 0px;
      }

    & h2{
    color: #5B5B5B;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
    letter-spacing: 1.0px;
    margin-bottom:40px;
    display:inline-block;

      @media ${THEME.TABLET} {
        display:none;
      }
    }

  & p{
    color: #5B5B5B;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 150% */
    letter-spacing: -1.61px;
    margin-bottom:40px
  }
  & ul {
    padding:0 20px;
    &  li{
    color: #5B5B5B;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px; /* 229.167% */
    letter-spacing: -1.61px;
  }
  }
`

export const WorkWithSection = styled.section`
  padding:8rem 0;
  /* background:green; */
`

export const WorkedTilte =styled.h1`
  font-size:40px;
  line-height:70px;
  font-weight:700;
  text-align:center;
`


export const MovingCardHolder = styled.div`
    padding: 60px 0;
    position: relative;
    z-index: 2;

    @media ${THEME.TABLET} {
        padding: 100px 0;
    }
`

export const ClientLogoWrapper = styled.div`
  display:flex;
  align-items:center;
  gap:20px;
  margin:10px;
`

export const ClientsLogoHolder = styled.div`
  width: 240px;
  height: 246px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  display:flex;
  align-items:center;
  padding:20px;
  justify-content:center;
  box-shadow: 7px 9px 37px 0px rgba(0, 0, 0, 0.03);

  @media ${THEME.TABLET} {
    width:276px;
    height: 282px;
    aspect-ratio:16/9;
  }

  & img{
    max-width:100%;
    /* width:100%; */
  }
`