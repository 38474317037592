import React, { Fragment } from 'react'
import { Container } from './Style'
import img from '../../Assets/Images/Others/PageNotFound.webp'
import { NavBar } from '../../Components/NavBar/NavBar'

const PageNotFound = () => {

    return (
        <Fragment>
            <NavBar />
        <Container>
            <img src={img} alt='Page Not Found' />

            <div className='holder'>
                <h1>Something is wrong</h1>
                <h2>The page you are looking for was moved, removed, renamed or might never existed.</h2>
            </div>
        </Container>
        </Fragment>
    )
}

export default PageNotFound