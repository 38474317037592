import React, { useContext } from 'react'
import { FooterWrapper } from './Style'
import { useNavigate } from 'react-router-dom'
import logo from '../../../Assets/Images/companyDetails/blacklogo.webp'
import { socialfooterLinks } from '../../../Components/NavBar/Data'
import { Container } from '../../../Components/Container'
import { CustomCursorContext } from '../../../Components/others/CustomCursor/context/CustomCursorContext'

export const Footer = () => {

    const navigate = useNavigate();

    const { type, setType } = useContext(CustomCursorContext);

    return (
        <Container data-scroll data-scroll-speed={'-2'}>

            <FooterWrapper>
                <div className='talkHolder'>
                    <h1>Need an experienced and skilled hand with custom IT solutions?</h1>
                    <a href="/letstalk" className='goto' onMouseEnter={() => setType('button')}
                        onMouseLeave={() => setType('default')} >
                        <span>Let's Talk</span>
                    </a>
                </div>
                <div className='footercontent'>
                    <div className='logocontainer'>
                        <a href="/">
                            <img src={logo} alt="Ideaux Logo" style={{ cursor: 'pointer' }} />
                        </a>
                        <p>© 2023 Ideaux. All Rights Reserved.</p>
                    </div>
                    <div className='linkcontainer'>
                        <div className='socialLinks'>
                            {
                                socialfooterLinks.map((item) => (
                                    <a href={item.link} onMouseEnter={() => setType('hamburger')}
                                    onMouseLeave={() => setType('default')}  target='_blank' key={item.key}>{item.icon}</a>
                                ))
                            }
                        </div>
                        <div className='termsholder'>
                            <p>Terms & Conditions</p>
                            <p>Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </FooterWrapper>
        </Container>
    )
}
