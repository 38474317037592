import React from 'react'
import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';

export const Tabs = ({ items, onChange, defaultActiveKey,tabPosition }) => {

    return (
        <StyledTabs defaultActiveKey={defaultActiveKey} items={items} onChange={onChange} tabPosition={tabPosition} />
    )
}


const StyledTabs = styled(AntdTabs)`
    width:100%;
    height:80vh;
    /* margin-top:50px; */
    & .ant-tabs-tab-btn{
        font-size:1rem;
        font-weight:600;
    }
    &.ant-tabs .ant-tabs-content-holder {
        display:flex;
        align-items:center;
        justify-content:center;
    }
    &.ant-tabs >.ant-tabs-nav{
        width:50%;
        margin: 16px auto;
    }

@media screen {
    
}
`