import React, { useContext, useEffect, useState } from 'react'
import {
  TalkWrapper,
  ListWrapper,
  ContactWrapper,
  TalkNavLogo,
  TalkNavToggleBtn,
  ContactCard,
  ContactHolder,
  ModalContent,
} from './style'
import logo from '../../../Assets/Images/companyDetails/blacklogo.webp'
import toggleBtn from '../../../Assets/Images/Others/burger.webp'
import india from '../../../Assets/Images/Others/india.webp'
import { SubMenu } from '../../../Components/NavBar/SubMenu'
import Flex from '../../../Components/Flex'
import { Tabs } from '../../../Components/Tabs'
import { recruitmentData, timeNeedData } from '../../CustomData/AboutData'

import { Requirements } from './Requirements'
import { ProjectDescription } from './ProjectDescription'
import { TimeNeeds } from './TimeNeeds'
import { AddressForm } from './AddressForm'
import request from '../../../utils/request'
import axios from 'axios'
import { Modal, Spin } from 'antd'
import { CustomCursorContext } from '../../../Components/others/CustomCursor/context/CustomCursorContext'

export const LetsTalk = () => {
  const [activeBtn, setActiveBtn] = useState(false)
  const [stepCount, setStepCount] = useState(0)
  const [percentageCount, setPercentageCount] = useState(0)
  const [requirement, setRequirement] = useState([])
  const [needs, setNeeds] = useState({})

  const [loading, setLoading] = useState(false)
  const [modalContent, setModalContent] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [resetForm, setResetForm] = useState(false)


  const { type, setType } = useContext(CustomCursorContext);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [requirementData, setRequirementData] = useState({
    requirement: '',
    description: '',
    needs: '',
    address: ''
  })

  const POSTURL = 'send';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const UpdateActive = (arg) => {
    setActiveBtn(arg);
    // setRequirementData({})
  }

  const UpdateCount = (arg) => {
    setStepCount(arg)
  }

  const updateRerData = () => {   // --------  Update RequireData
    setRequirementData((prevData) => ({
      ...prevData,
      requirement: requirement,
    }));
  }

  const updateProjectDescription = (value) => {  // --------  Update Project Description
    setRequirementData((prevData) => ({
      ...prevData,
      description: value,
    }));
  }

  const updateProjectLimit = () => {  // --------  Update Project Time Limit
    setRequirementData((prevData) => ({
      ...prevData,
      needs: needs,
    }));
  }

  const updateAddress = (value) => {  // --------  Update Client Address
    setRequirementData((prevData) => ({
      ...prevData,
      address: value,
    }),);
    const newValue = {
      ...requirementData,
      address: value
    }

    sendData(newValue);
  }

  const sendData = async (value) => {
    const { address, description, needs, requirement } = value;
    const newValue = {
      email: address.email,
      mobileNumber: address.mobilenumber,
      description: description.description,
      userName: address.username,
      needs: needs.name,
      requirement: requirement.map((item) => ({ 'name': item.name })),
    }
    showModal();

    try {
      setLoading(true);

      setModalContent(0)
      const response = await request.post(`${POSTURL}`, newValue);
      if (response.status == 200) {

        setLoading(false);
        setRequirementData({
          requirement: '',
          description: '',
          needs: '',
          address: ''
        })
        setResetForm(true)
        setStepCount(0);
        setNeeds({});
        setRequirement([]);
        setModalContent(1)
      } else {
        setModalContent(2)
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        setModalContent(3)
      } else {
        setModalContent(2)
      }
    }
  }


  useEffect(() => {
    switch (stepCount) {
      case 1:
        setPercentageCount(50)
        break;

      case 2:
        setPercentageCount(75)
        break;

      case 3:
        setPercentageCount(100)
        break;

      default:
        setPercentageCount(25)
        break;
    }
  }, [stepCount])


  const onChange = (key) => {
  };

  const updateRequirements = (value) => {

    const selectedObject = recruitmentData.find((item) => item.key === value.key);

    // Check if the selected object is already in the array
    const isAlreadySelected = requirement?.some((item) => item?.key === value.key);

    // // If it's not in the array, add it; otherwise, remove it
    if (!isAlreadySelected) {
      setRequirement((prevSelected) => [...prevSelected, selectedObject]);
    } else {
      setRequirement((prevSelected) => prevSelected.filter((item) => item.key !== value.key));
    }
  }

  const updateNeeds = (arg) => {
    setNeeds(arg)
  }

  const items = [
    {
      key: '1',
      label: '',
      children: <Requirements />,
    },
    {
      key: '2',
      label: 'Tab 2',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tab 3',
      children: 'Content of Tab Pane 3',
    },
  ];

  let pageView;

  switch (stepCount) {
    case 0:
      pageView = <Requirements data={recruitmentData} update={updateRequirements} selected={requirement} count={UpdateCount} updatedata={updateRerData} />
      break;

    case 1:
      pageView = <ProjectDescription updatedata={updateProjectDescription} count={UpdateCount} />
      break;

    case 2:
      pageView = <TimeNeeds data={timeNeedData} count={UpdateCount} updatedata={updateProjectLimit} selected={needs} update={updateNeeds} />
      break;

    case 3:
      pageView = <AddressForm updatedata={updateAddress} reset={resetForm} />
      break;

    default:
      pageView = 'Empty'
      break;
  }

  let modalContentData;

  switch (modalContent) {
    case 0:
      modalContentData = <h1>Your Mail is Sending... <br /> Please Wait</h1>
      break;

    case 1:
      modalContentData = <h1>We received your Mail. <br /> Thankyou</h1>
      break;

    case 2:
      modalContentData = <h1>Something Went Wrong. <br /> Please Try Again</h1>
      break;

    default:
      modalContentData = <h1>NetWork Error.  <br /> Please Try Again</h1>
      break;
  }

  return (
    <TalkWrapper>
      <TalkNavLogo>
        <a href="/">
          <img src={logo} alt="company Logo" />
        </a>
      </TalkNavLogo>
      <TalkNavToggleBtn onClick={() => setActiveBtn(!activeBtn)} onMouseEnter={() => setType('hamburger')}
        onMouseLeave={() => setType('default')}>
        <div>
          <span></span>
          <span></span>
        </div>
        {/* <img src={toggleBtn} alt="company Logo" /> */}
      </TalkNavToggleBtn>

      <ListWrapper percentage={percentageCount}>
        <div className='progress' ></div>
        {pageView}
      </ListWrapper>

      <ContactWrapper>
        <ContactHolder>
          <ContactCard>
            <h2>Contact Us</h2>
            <div className='content__wrapper'>
              <div className='holder'>
                <img src={india} alt="India Flag" />
              </div>
              <div className='content'>
                <h6>309 , Eden Brook ,</h6>
                <h6>Rajakamangalam Road , Ramanputhur ,</h6>
                <h6>Nagercoil , Kanyakumari dist .</h6>
                <h6>Tamil Nadu 629002</h6>
                <h6>Phone : +91 8903220363 , +91 9629536388</h6>
                <div style={{ marginTop: '20px' }}>
                  <a href="mailto:hr@ideaux.in">hr@ideaux.in</a>
                </div>
              </div>
            </div>
          </ContactCard>


          <ContactCard>
            <h2>For Careers</h2>
            <div className='content__wrapper'>
              <div className='holder'></div>
              <div className='content'>
                <div style={{ marginBottom: '20px' }}>
                  <a href="mailto:hr@ideaux.in">hr@ideaux.in</a>
                </div>
                <h6>Phone : +91 8903220363 , +91 9629536388</h6>
              </div>
            </div>
          </ContactCard>
        </ContactHolder>
      </ContactWrapper>
      <SubMenu activeBtn={activeBtn} updateactive={UpdateActive} />

      <Modal title="Mail" open={isModalOpen}
        onCancel={handleCancel} onOk={handleOk}
        okButtonProps={{
          disabled: loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}>
        <ModalContent>
          {
            modalContentData
          }
        </ModalContent>
        <Spin tip="Loading" size="large" spinning={loading}>
          <div className="content" />
        </Spin>
      </Modal>
    </TalkWrapper>
  )
}
