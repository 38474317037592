import React, { Fragment, useContext, useState } from 'react'
import {
    Header,
    TopTitle,
    Nav,
    NavLogo,
    MenuItemsHolder,
    NavToggleBtn,
    MenuItemsWrapper,
    MenuLink,
    TopContainer,
} from './styled'
import blacklogo from '../../Assets/Images/companyDetails/blacklogo.webp'
import whitelogo from '../../Assets/Images/companyDetails/whitelogo.webp'
import { navItems } from './Data'
import { Container } from '../Container'
import { SubMenu } from './SubMenu'
import { SecondaryNav } from './SecondaryNav'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomCursorContext } from '../others/CustomCursor/context/CustomCursorContext'


export const NavBar = ({ hold, dark, sticky }) => {
    // const [linkList, setLinkList] = useState(linkData)
    const [activeBtn, setActiveBtn] = useState(false)
    // const [activeClass, setActiveClass] = useState(linkList[0].key);
    const { type, setType } = useContext(CustomCursorContext);

    const secondaryNavUpdate = (arg) => {
        setActiveBtn(arg);
    }

    const location = useLocation();
    const navigate = useNavigate()

    return (
        <Fragment >

            <Header hold={hold}>
                <TopContainer>
                    <TopTitle>Custom software solutions to fit your Business needs</TopTitle>
                </TopContainer>
                <Nav>
                    <MenuItemsHolder>
                        <NavLogo onClick={() => navigate('/')}>
                            {
                                dark ?
                                    (
                                        <img src={whitelogo} alt="BrandPartner Logo" />
                                    ) : (
                                        <img src={blacklogo} alt="BrandPartner Logo" />
                                    )
                            }
                        </NavLogo>

                        <MenuItemsWrapper>
                            {
                                navItems?.map((item, index) => (
                                    // <MenuLink to={item.path} key={index} className={location.pathname === item.path ? 'active' : ''} >
                                    <a href={item.path} key={index} className={location.pathname === item.path ? 'active' : ''}
                                        onMouseEnter={() => setType('hamburger')}
                                        onMouseLeave={() => setType('default')}>
                                        <span data-title={item.name}>{item.name}</span>
                                        {/* <span style={{'--i':'0'}}>{item.name}</span>
                                            <span style={{'--i':'1'}}>{item.name}</span>
                                            <span style={{'--i':'2'}}>{item.name}</span>
                                            <span style={{'--i':'3'}}>{item.name}</span> */}
                                    </a>
                                    // </MenuLink>
                                ))
                            }

                        </MenuItemsWrapper>

                    </MenuItemsHolder>

                    <NavToggleBtn hold={hold} onClick={() => setActiveBtn(!activeBtn)}
                        onMouseEnter={() => setType('hamburger')}
                        onMouseLeave={() => setType('default')}>
                        <span className={activeBtn ? 'active' : ''}></span>
                        <span className={activeBtn ? 'active' : ''}></span>
                    </NavToggleBtn>
                </Nav>
            </Header>
            <SubMenu activeBtn={activeBtn} updateactive={secondaryNavUpdate} />
            <SecondaryNav sticky={sticky} style={{ opacity: sticky ? 1 : 0 }} activeBtn={activeBtn} updateactive={secondaryNavUpdate} />
        </Fragment>
    )
}
