
import React, { useState } from 'react'
import { CustomCursorContext } from './CustomCursorContext'

export const CustomCursorManger = ({ children }) => {

    const [type, setType] = useState('default')

    return (
        <CustomCursorContext.Provider value={{ type, setType }}>
            {children}
        </CustomCursorContext.Provider>
      
    )
}
