import { Col, Form, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { CustomRow } from "../../../Components/CustomRow";
import { CustomPageFormTitle } from "../../../Components/CustomPageTitle";
import { CustomSelect } from "../../../Components/CustomSelect";
import { CustomTextArea } from "../../../Components/CustomTextArea";
import { CustomInput } from "../../../Components/CustomInput";
import Flex from "../../../Components/Flex";
import Button from "../../../Components/CustomButton";
import { CustomInputNumber } from "../../../Components/CustomInputNumber";
import { CustomUpload } from "../../../Components/CustomUpload";
import { CustomRadioButton } from "../../../Components/CustomRadioButton";
import EducationInformation from "./EducationInformation";
import { StyledApply } from "./style";

const SkillsInformation = ({
  count,
  updatedata,
  jobDetails,
  loading,
  success,
  FormExternalClose
}) => {
  const [ImageInitialValue, setImageInitialValue] = useState([]);
  const [fileName, setFileName] = useState();
  const [form] = Form.useForm(); // ----- Define Form

  useEffect(() => {
    if (jobDetails) {
      form.setFieldsValue({
        jobRole: jobDetails?.jobTitle,
        departmentId: jobDetails?.applicationId,
        position: jobDetails?.position,
      });
    }
  }, [jobDetails]);

  const onFinish = (values) => {
    const newValue = {
      ...values,
      fileName:fileName,
    }
    updatedata(newValue);
  };
  
  const back = () => {
    count(1);
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  const [value, setValue] = useState("");
  
  const handleChange = (e) => {
    form.setFieldsValue({ year: null });
    setValue(e.target.value);
  };
  
  const handleClose = () =>{
    FormExternalClose()
    count(0)
    form.resetFields();
  }

  const handlePic = (value) => {
    setFileName(value.file.name.slice(-3))
  };

  return (
    <StyledApply>
      {loading ? (
        <div
          style={{
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Spin tip="Loading" size="large"></Spin>
          <h2>Loading ...</h2>
        </div>
      ) : success ? (
        <div style={{height:"150px",textAlign:"center"}}>
        <h1 style={{color:"green"}}>Success</h1>
        <p style={{fontSize:"18px"}}>Your Details have been received😊</p>
        <button onClick={handleClose} style={{padding:"8px 30px",background:"#9BCF53",color:"#fff", borderRadius:"5px", border:"none",margin:"20px 0px"}}>Continue</button>
      </div>
      ) : (
        <Form
          form={form}
          name={"Skills Information"}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="animate fadeInRight four"
        >
          <CustomRow space={[24, 24]}>
            <Col span={24} md={24}>
              <CustomPageFormTitle Heading={"Skills Information"} />
            </Col>

            <Col span={24} md={12}>
              <CustomInput
                name={"jobRole"}
                label={"Department"}
                disabled={"disabled"}
              />
              <CustomInput name={"departmentId"} display={"none"} />
            </Col>

            <Col span={24} md={12}>
              <CustomInput
                name={"position"}
                label={"Position"}
                disabled={"disabled"}
              />
              {/* <CustomInput name={"departmentId"} display={"none"} /> */}
            </Col>

            <Col span={24} md={12}>
              <CustomInputNumber
                label={"Salary Expectation"}
                name={"salaryExpectations"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Salary Expectation !",
                  },
                ]}
              />
            </Col>

            <Col span={24} md={12}>
              <CustomRadioButton
                label={"Select Experience"}
                options={[
                  { label: "Fresher", value: "fresher" },
                  { label: "Experirnce Minimum One Year", value: "experience" },
                ]}
                name={"workExperience"}
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "Please Select Experience !",
                  },
                ]}
              />
            </Col>

            {value === "experience" && (
              <Col span={24} md={12}>
                <CustomInputNumber
                  label={"Years Of Experience"}
                  addonAfter={"Year"}
                  name={"year"}
                  min={1.0}
                  precision={1}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Years !",
                    },
                  ]}
                />
              </Col>
            )}

            <Col span={24} md={12}>
              <CustomInput
                label={"Skill Details"}
                name={"skillDetails"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Skills",
                  },
                ]}
              />
            </Col>

            <Col span={24} md={12}>
              <CustomTextArea
                label={"Cover Letter"}
                name={"coverLetter"}
                placeholder={"Enter Cover Letter"}
              />
            </Col>

            <Col span={24} md={12}>
              <CustomUpload
                form={form}
                label={"Resume"}
                name={"resume"}
                initialValue={ImageInitialValue}
                maxCount={1}
                accept={".pdf"}
                onChange={handlePic}
                rules={[
                  {
                    required: true,
                    message: "Please Select Resume",
                  },
                ]}
              />
            </Col>
          </CustomRow>
          <Flex gap={"20px"} center={"true"} margin={"20px 0"}>
            <Button.Danger text={"Back"} onClick={() => back()} />
            <Button.Success text={"Submit"} htmlType={"submit"} />
          </Flex>
        </Form>
      )}
    </StyledApply>
  );
};

export default SkillsInformation;
