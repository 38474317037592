import React from 'react'
import styled, { keyframes } from 'styled-components'
import { THEME } from '../Theme'
import logo from '../Assets/Images/companyDetails/blacklogo.webp'

const PreLoader = () => {
    return (
        <Container>
            <img src={logo} alt={'Company Logo'} className='logo' />

            <Ring className="ring1" />
            <Ring className="ring2" />
            <Ring className="ring3" />

            <span className='loading'>Loading ...</span>
        </Container>
    )
}

export default PreLoader

// Define keyframe animation
const rotateAnimation1 = keyframes`
    from {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    to {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
`;

const rotateAnimation2 = keyframes`
    from {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    to {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
`;

const rotateAnimation3 = keyframes`
    from {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    to {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
`;

const Container = styled.div`
    height:100vh;
    display:flex;
    align-items:center;
    justify-content:center;
    background:#fff;
    position:relative;

    & .logo{
        position:absolute;
        max-width:100%;
        width:300px;
        top:20%;
        left:50%;
        transform:translateX(-50%);
    }

    & .loading{
        color:${THEME.primary};
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }
`

const Ring = styled.div`
    width: 200px;
    height: 200px;
    border: 0px solid #fff;
    position: absolute;
    border-radius: 50%;

    &.ring1 {
        border-bottom-width: 8px;
        border-color: rgb(255, 0, 255);
        animation: ${rotateAnimation1} 2s linear infinite;
    }

    &.ring2 {
        border-right-width: 8px;
        border-color: rgb(0, 247, 255);
        animation: ${rotateAnimation2} 2s linear infinite;
    }

    &.ring3 {
        border-top-width: 8px;
        border-color: rgb(0, 255, 13);
        animation: ${rotateAnimation3} 2s linear infinite;
    }
`;