import React from 'react'
import {
    HomeCarouselBottomWrapper,
    SwiperCardHolderOutline,
    SwiperSildeHolder,
    SwiperSildeWrapper,
    SwiperTitle
} from './Style'
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { CarouselImg } from '../../CustomData/Data';

export const SwiperCard = () => {

    return (
        <SwiperCardHolderOutline>
            <SwiperTitle>
                We've Worked With Some Cool People.
            </SwiperTitle>

            <div style={{ height: '455px' }}>
                <SwiperSildeWrapper
                    className="mySwiper"
                    loop={true}
                    slidesPerView={1}
                    spaceBetween={50}
                    centeredSlides={true}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: true,
                    }}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    {
                        CarouselImg?.map((item, index) => (
                            <SwiperSildeHolder key={index}>
                                <div>
                                    <img src={item.name} alt="Projects" />
                                </div>
                            </SwiperSildeHolder>
                        ))
                    }
                </SwiperSildeWrapper>
            </div>

            <div style={{ position: 'absolute', bottom: '20px', width: '100%', left: '0' }}>
                <HomeCarouselBottomWrapper>
                    <span className='count'>
                        300+
                    </span>
                    <span className='text'>Satisfied Clients</span>
                </HomeCarouselBottomWrapper>
            </div>
        </SwiperCardHolderOutline>

    )
}

{/* <div style={{ height: '100%', width: '100%',po}}>
<SwiperTitle>
    We've Worked With Some Cool People.
</SwiperTitle>

<div style={{ height: '455px' }}>
    <SwiperSildeWrapper
        className="mySwiper"
        loop={true}
        slidesPerView={1}
        spaceBetween={50}
        centeredSlides={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: true,
        }}
        breakpoints={{
            850: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        }}
        modules={[Autoplay, Pagination, Navigation]}
    >
        {
            CarouselImg?.map((item,index) => (
                <SwiperSildeHolder key={index}>
                    <div>
                        <img src={item.name} alt="Projects" />
                    </div>
                </SwiperSildeHolder>
            ))
        }
    </SwiperSildeWrapper>
</div>

<div style={{position:'absolute',bottom:'20px',width:'100%',left:'0'}}>
<HomeCarouselBottomWrapper>
    <span className='count'>
        300+
    </span>
    <span className='text'>Satisfied Clients</span>
</HomeCarouselBottomWrapper>
</div>
</div> */}
